<template>
  <section class="specials specials__animation" ref="specialsComponent">
    <HeadingGray :HeadingText="$t('specialsHeader')"></HeadingGray>
    <SpecialsList></SpecialsList>
  </section>
</template>

<script>
import HeadingGray from "../Header/HeadingGray.vue";
import SpecialsList from "./SpecialsList.vue";
import { useStore } from "../../store";

export default {
  components: {
    HeadingGray,
    SpecialsList,
  },
  computed: {
    mainOffer() {
      return useStore().getMainOffer;
    }
  },
  mounted() {
    if (useStore().specialsScroll) {
      this.$refs.specialsComponent.scrollIntoView({ behavior: "smooth" });
      useStore().resetScrollAction(false);
    }
  },
};
</script>
