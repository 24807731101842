<template>
  <div class="configuration__wrapper">
    <ul class="configuration__list">
      <li
        class="configuration__element"
        v-for="(item, index) in configurationItems"
        :key="index"
      >
        <div class="element__block">
          <svg>
            <image :href="item.iconHref"></image>
          </svg>
          <p v-if="item.info" class="element__info">{{ item.info }}</p>
          <p v-if="item.detail" class="element__detail">
            {{ capitalizeFirstLetter(item.detail) }}
          </p>
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
import { useStore } from "../../store";
import { capitalizeFirstLetter } from "../../plugins/helpers";

export default {
  data() {
    return {
      configurationItems: [
        {
          iconHref: require("../../assets/Icons/ico_mb_silnik_02.svg"),
          info: "Silnik",
          detail: "",
        },
        {
          iconHref: require("../../assets/Icons/ico_mb_skrzynia.svg"),
          info: "Skrzynia biegów",
          detail: "",
        },
        {
          iconHref: require("../../assets/Icons/ico_mb_pojemnosc_02.svg"),
          info: "Pojemność silnika",
          detail: "",
        },
        {
          iconHref: require("../../assets/Icons/ico_mb_moc.svg"),
          info: "Moc silnika",
          detail: "",
        },
      ],
    };
  },
  computed: {
    mainOffer() {
      return useStore().getMainOffer;
    },
  },
  methods: {
    capitalizeFirstLetter,
    getData() {
      if (this.mainOffer[0]) {
        const vehicleData = this.mainOffer[0];
        if (vehicleData.engineCapacity) {
          this.configurationItems.forEach((item, index) => {
            switch (index) {
              case 0:
                item.detail = vehicleData.fuelType;
                break;
              case 1:
                item.detail = vehicleData.gearboxType;
                break;
              case 2:
                item.detail = vehicleData.engineCapacity + "cm³";
                break;
              case 3:
                item.detail = vehicleData.power;
                break;
              default:
                break;
            }
          });
        }
      }
    },
  },
  mounted() {
    this.getData();
  },
  watch: {
    mainOffer(newValue) {
      if (newValue) {
        this.getData();
      }
    },
  },
};
</script>
