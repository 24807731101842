<template>
        <table class="comparing__side--table">
          <tr class="comparing__side--table-element" v-for="(item, index) in items" :key="index" :class="{'no-display': index >= visibleRows}">
          {{ capitalizeFirstLetter(item.item) }}
          </tr>
        </table>
</template>

<script>
import { capitalizeFirstLetter } from '../../plugins/helpers';

    export default {
        props: {
            items: Array,
            visibleRows: Number
        },
        methods: {
            capitalizeFirstLetter
        }
    }
</script>