<template>
  <table
    v-if="files && files.length > 0"
    class="yourfiles__table"
    cellspacing="0"
    ref="trackComponent"
  >
    <tr class="yourfiles__row">
      <th class="yourfiles__header">{{ $t("commentary") }}</th>
      <th class="yourfiles__header">{{ $t("exposeDate") }}</th>
      <th class="yourfiles__header">{{ $t("name") }}</th>
    </tr>
    <tr class="yourfiles__row" v-for="(file, index) in files" :key="index">
      <td class="yourfiles__data">{{ file.description }}</td>
      <td class="yourfiles__data">24.08.2023</td>
      <td class="yourfiles__data">{{ file.type }}</td>
      <td class="yourfiles__data">
        <svg
          class="yourfiles__icon"
          @click="
            downloadFile(
              file.url,
              file.type,
              file.id,
              file.mimeType,
              file.extension,
              file.description
            )
          "
        >
          <image
            class="yourfiles__icon--image"
            href="../../assets/Icons/pobierz.svg"
          />
        </svg>
      </td>
    </tr>
  </table>
</template>

<script>
import { useStore } from "../../store";
import { isInViewport } from "../../plugins/helpers";
import httpActions from "../../plugins/httpActionService";

export default {
  props: {
    allFiles: Boolean,
  },
  data() {
    return {
      files: "",
      mountedComponent: false,
      section: "h9ld36",
      download: "jhr2e3",
      isInView: false,
      isInViewFlag: false,
      allowToTrack: false,
      allowToTrackFlag: false,
    };
  },
  computed: {
    mainOffer() {
      return useStore().getMainOffer;
    },
  },
  methods: {
    getData() {
      if (this.allFiles) {
        this.files = useStore().data.files;
      } else {
        this.files = useStore().data.files.filter(
          (file) => file.vehicle_id === this.mainOffer[0].id
        );
      }
    },
    checkViewport() {
      const element = this.$refs.trackComponent;
      if (element) {
        this.isInView = isInViewport(element);
        if (this.isInView && !this.isInViewFlag) {
          this.isInViewFlag = true;
          this.allowToTrack = true;
          setTimeout(() => {
            if (this.allowToTrack && this.allowToTrackFlag) {
              const arr = [];
              arr.push(useStore().mainOffer[0].id);
              httpActions.trackSection(this.section, arr);
            }
            this.allowToTrackFlag = false;
            this.isInViewFlag = false;
          }, 1500);
        }
        if (this.isInView === false) {
          this.allowToTrack = false;
        }
      }
    },

    downloadFile(url, type, id, mimeType, extension, description) {
      httpActions.trackFiles(this.download, id, useStore().defaultOfferID);
      httpActions.downloadFile(url, type, mimeType, extension, description);
    },
  },
  mounted() {
    this.getData();
    this.checkViewport();
    window.addEventListener("scroll", this.checkViewport);
  },
  beforeUnmount() {
    window.removeEventListener("scroll", this.checkViewport);
  },
  watch: {
    isInView(newVal) {
      if (newVal) {
        this.allowToTrackFlag = true;
      }
    },
    mainOffer(newVal) {
      if (newVal) {
        this.allowToTrackFlag = true;
        this.checkViewport();
        this.getData();
      }
    },
  },
};
</script>
