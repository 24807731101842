<template>
  <div class="slider__car--block">
    <p v-if="mainOffer[0] && mainOffer[0].forYouPrice" class="slider__car--font">
      {{ $t("offerForYou") }}
    </p>
    <p v-if="mainOffer[0] && !mainOffer[0].forYouPrice" class="slider__car--font">
      {{ $t("catalogPrice") }}
    </p>
    <div class="slider__car--description">
      <p v-if="mainOffer[0] && mainOffer[0].forYouPrice" class="slider__car--price">
        {{ formatPrice(mainOffer[0].forYouPrice, false) }} zł <span class="slider__car--font">brutto</span>
      </p>
      <p v-if="mainOffer[0] && !mainOffer[0].forYouPrice" class="slider__car--price">
        {{ formatPrice(mainOffer[0].catalogPrice, false) }} zł <span class="slider__car--font">brutto</span>
      </p>
    </div>
    <p v-if="mainOffer[0] && mainOffer[0].catalogPrice && mainOffer[0].forYouPrice" class="slider__car--font">
      {{ $t("catalogPrice") }}
      <span>{{ formatPrice(mainOffer[0].catalogPrice, false) }} zł brutto</span>
    </p>
  </div>
</template>

<script>
import { useStore } from "../../store";
import { formatPrice } from "../../plugins/helpers";

export default {
  computed: {
    mainOffer() {
      return useStore().getMainOffer;
    },
  },
  methods: {
    formatPrice,
  },
};
</script>
