<template>
  <div v-if="files && files.length > 0" class="yourfiles__container--mobile">
    <div
      v-for="(file, index) in files"
      :key="index"
      class="yourfiles__block--mobile"
    >
      <p class="yourfiles__block--header">{{ file.name }}</p>
      <p class="yourfiles__block--date">24.08.2023</p>
      <p class="yourfiles__block--text">{{ file.description }}</p>
      <svg
        class="yourfiles__download--icon"
        @click="downloadFile(file.url, file.name, file.id, file.mimeType, file.extension, file.description)"
      >
        <image
          class="yourfiles__download--image"
          href="../../assets/Icons/pobierz.svg"
        ></image>
      </svg>
    </div>
  </div>
</template>

<script>
import { useStore } from "../../store";
import httpActions from "../../plugins/httpActionService";

export default {
  props: {
    allFiles: Boolean,
  },
  data() {
    return {
      files: "",
      mountedComponent: false,
      download: "jhr2e3",
    };
  },
  computed: {
    mainOffer() {
      return useStore().getMainOffer;
    },
  },
  methods: {
    getData() {
      if (this.allFiles) {
        this.files = useStore().data.files;
      } else {
        this.files = useStore().data.files.filter(
          (file) => file.vehicle_id === this.mainOffer[0].id
        );
      }
    },
    downloadFile(url, type, id, mimeType, extension, description) {
      httpActions.trackFiles(this.download, id, useStore().defaultOfferID);
      httpActions.downloadFile(url, type, mimeType, extension, description);
    },
  },
  mounted() {
    this.getData();
  },
};
</script>
