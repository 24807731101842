<template>
  <div
    class="sidebar__mobile"
    :class="{
      'sidebar__transform--right': this.menuIsOpen,
      'sidebar__transform--left': !this.menuIsOpen,
    }"
    ref="sidebarComponent"
  >
    <div
      :class="{
        'sidebar__block-date': returnColor === '',
        'sidebar__block-date--bordo': returnColor === '#801C62',
      }"
    >
      <p class="sidebar__text">{{ $t("offerExpiration") }}</p>
      <p class="sidebar__date" ref="dateComponent"></p>
    </div>
    <SideConsultantContainerMobile></SideConsultantContainerMobile>
    <SideOffersContainerMobile
      :compareIsNotActive="compareIsNotActive"
    ></SideOffersContainerMobile>
    <button
      :class="{
        sidebar__compare: returnColor === '',
        'sidebar__compare--bordo': returnColor === '#801C62',
        'sidebar__compare--allowed': allowToClick,
      }"
      @click="openCompareComponent"
      ref="compareButton"
    >
      {{ $t("compareMatched") }}
    </button>
    <button
      v-if="allFiles && allFiles.length > 0"
      class="sidebar__files"
      @click="handleFilesButton"
    >
      {{ $t("yourFilesHeader") }}
    </button>
  </div>
  <div class="sidebar__mobile--main">
    <div class="sidebar__helper">
      <div
        v-for="(button, index) in buttons"
        :key="button"
        :class="{
          'button__is-clicked':
            selectedIndex.includes(index) && returnColor === '',
          'sidebar__mobile--element':
            returnColor === '',
          'button__is-clicked--bordo':
            selectedIndex.includes(index) && returnColor === '#801C62',
          'sidebar__mobile--element-bordo':
            returnColor === '#801C62',
        }"
        @click="operateMenu('open')"
      >
        <svg width="16" height="16">
          <image
            width="16"
            height="16"
            href="../../assets/Icons/arrow_left_test.png"
          ></image>
        </svg>
      </div>
    </div>
  </div>
  <div v-if="menuIsOpen" class="blur" @click="operateMenu('hide')"></div>
</template>

<script>
import SideConsultantContainerMobile from "./SideConsultantContainerMobile.vue";
import SideOffersContainerMobile from "./SideOffersContainerMobile.vue";
import Notiflix from "notiflix";
import { useStore } from "../../store";
import dataToCompare from "../../plugins/dataToCompare";

export default {
  components: {
    SideConsultantContainerMobile,
    SideOffersContainerMobile,
  },
  data() {
    return {
      compareIsNotActive: true,
      allowToClick: false,
      menuIsOpen: false,
      buttons: [],
      iconsURLs: [
        {
          icon: require("../../assets/Icons/ico_mb_twoj_samochod.png"),
        },
        {
          icon: require("../../assets/Icons/ico_mb_twoj_parametry.png"),
        },
        {
          icon: require("../../assets/Icons/ico_mb_twoj_pakiety.png"),
        },
        {
          icon: require("../../assets/Icons/ico_mb_twoj_akcesoria.png"),
        },
        {
          icon: require("../../assets/Icons/ico_mb_twoj_finansowanie.png"),
        },
      ],
      selectedIndex: [],
    };
  },
  emits: ["menuIsOpen"],
  computed: {
    isShown() {
      return useStore().compareButtonClicked;
    },
    clickedButtonsCount() {
      return useStore().clickedButtonsCount;
    },
    allFiles() {
      if (useStore().data) {
        return useStore().data.files;
      }
    },
    returnColor() {
      return useStore().getColor;
    },
  },
  methods: {
    operateMenu(action) {
      if (action === "open") {
        this.menuIsOpen = true;
      } else {
        this.menuIsOpen = false;
      }
      this.$emit("menuIsOpen", this.menuIsOpen);
      this.selectedIndex = useStore().offersIndex;
    },

    openCompareComponent() {
      if (this.isShown === true && this.clickedButtonsCount >= 2) {
        this.operateMenu("hide");
        this.$nextTick(() => {
          localStorage.setItem(
            "dataToCompare",
            JSON.stringify(dataToCompare.data)
          );
          this.$router.push("/compare");
          this.compareIsNotActive = false;
        });
      } else {
        Notiflix.Notify.warning(
          "Wybierz przynajmniej dwie pozycje do porównania"
        );
      }
    },
    downloadFile(url, name, id, mimeType) {
      httpActions.trackFiles(this.download, id, useStore().defaultOfferID);
      httpActions.downloadFile(
        url,
        name +
          "-" +
          useStore().mainOffer[0].make +
          useStore().mainOffer[0].model,
        mimeType
      );
    },
    loadData() {
      if (useStore().data) {
        const dateComponent = this.$refs.dateComponent;
        const expirationDate = new Date(useStore().data.offer.expirationDate);
        const currentTime = new Date();
        const timeDifference = expirationDate - currentTime;

        if (timeDifference > 0) {
          const intervalId = setInterval(() => {
            const currentTime = new Date();
            const timeDifference = expirationDate - currentTime;

            if (timeDifference <= 0) {
              clearInterval(intervalId);
              dateComponent.textContent = "0h : 0m : 0s";
            } else {
              const days = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
              const hours = Math.floor(
                (timeDifference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
              );
              const minutes = Math.floor(
                (timeDifference % (1000 * 60 * 60)) / (1000 * 60)
              );
              const seconds = Math.floor((timeDifference % (1000 * 60)) / 1000);

              const formattedHours = hours < 10 ? `0${hours}` : hours;
              const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;
              const formattedSeconds = seconds < 10 ? `0${seconds}` : seconds;

              if (days === 0) {
                dateComponent.textContent = `${formattedHours}h : ${formattedMinutes}m : ${formattedSeconds}s`;
              } else {
                dateComponent.textContent = `${days}d : ${formattedHours}h : ${formattedMinutes}m : ${formattedSeconds}s`;
              }
            }
          }, 1000);
        } else {
          dateComponent.textContent = "Oferta wygasła";
        }
        this.buttons = useStore().data.vehicles;
      }
    },
    handleFilesButton() {
      useStore().set("filesButtonClicked", true);
      if (this.$route.path === "/compare") {
        this.$router.push("/main");
      }
    },
  },
  mounted() {
    this.loadData();
    if (this.$route.path === "/compare") {
      this.compareIsNotActive = false;
    }
  },
  beforeUnmount() {
    useStore().resetIndex();
  },
  watch: {
    clickedButtonsCount: function () {
      if (this.clickedButtonsCount >= 2) {
        this.allowToClick = true;
      } else {
        this.allowToClick = false;
      }
    },
  },
};
</script>
