<template>
  <login></login>
</template>

<script>
import Login from "../Login/Login.vue";
import httpActions from "../../plugins/httpActionService";

export default {
  components: {
    Login,
  },
  mounted() {
    httpActions.getAssets();
    httpActions.tokenActive("/main", "/login", this.$route.query);
  },
};
</script>
