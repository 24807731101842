<template>
  <div class="accesorries__container">
    <svg
      class="slider__icon accesorries__prev"
      width="23px"
      height="40px"
      @click="slideAccessories('left')"
    >
      <image href="../../assets/Icons/arrow_left.svg"></image>
    </svg>
    <div class="accesorries__wrapper">
      <Carousel ref="myCarousel" :mouse-drag="mouseDrag" :touch-drag="touchDrag">
        <Slide v-for="(accessory, index) in accessoriesData" :key="index">
          <div class="accesorries__content">
            <img class="accesorries__image" :src="accessory.photo_url" />
            <p class="accesorries__info">{{ accessory.name }}</p>
            <p class="accesorries__price">
              {{ formatPrice(accessory.price_brutto) + " zł brutto" }}
            </p>
          </div>
        </Slide>
      </Carousel>
    </div>
    <svg
      class="slider__icon accesorries__next"
      width="23px"
      height="40px"
      @click="slideAccessories('right')"
    >
      <image href="../../assets/Icons/arrow_right.svg"></image>
    </svg>
  </div>
</template>

<script>
import { useStore } from "../../store";
import { formatPrice } from "../../plugins/helpers";
import "vue3-carousel/dist/carousel.css";
import { Carousel, Slide } from "vue3-carousel";

export default {
  components: {
    Carousel,
    Slide,
  },
  data() {
    return {
      accessoriesData: [],
      mouseDrag: false,
      touchDrag: false,
      windowWidth: {
        desktopWidth: 1800,
        tabletWidth: 1450,
        mobileWidth: 980,
      },
    };
  },
  methods: {
    slideAccessories(direction) {
      if (direction === "left") {
        this.$refs.myCarousel.prev();
      } else {
        const { currentSlide, slidesCount } = this.$refs.myCarousel.data;
        const windowWidth = window.innerWidth;

        switch (true) {
          case windowWidth >= this.windowWidth.desktopWidth &&
            currentSlide.value === slidesCount.value - 4:
          case windowWidth < this.windowWidth.desktopWidth &&
            windowWidth >= this.windowWidth.tabletWidth &&
            currentSlide.value === slidesCount.value - 3:
          case windowWidth < this.windowWidth.tabletWidth &&
            windowWidth >= this.windowWidth.mobileWidth &&
            currentSlide.value === slidesCount.value - 2:
          case windowWidth < this.windowWidth.mobileWidth &&
            currentSlide.value === slidesCount.value - 1:
            return;
          default:
            this.$refs.myCarousel.next();
        }
      }
    },

    allowMouseDrag() {
      if (window.innerWidth < this.windowWidth.mobileWidth) {
        this.mouseDrag = true;
      } else {
        this.mouseDrag = false;
      }
    },
    formatPrice,
    getData() {
      this.accessoriesData =
        useStore().data.offers[0].vehicle_data[0].accessories;
    },
  },
  mounted() {
    window.addEventListener("resize", this.allowMouseDrag);
    this.getData();
  },
  beforeUnmount() {
    window.removeEventListener("resize", this.allowMouseDrag);
  },
};
</script>