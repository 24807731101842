import axios from "axios";
import { router } from "../index";
import Notiflix from "notiflix";

axios.defaults.withCredentials = true;

const makeApiRequest = async (
  method,
  url,
  data,
  config,
  tokenExpiredDestination
) => {
  try {
    let response;

    switch (method) {
      case "get":
        response = await axios.get(url, config);
        handleResponse(response, tokenExpiredDestination);
        break;

      case "post":
        response = await axios.post(url, data, config);
        handleResponse(response, tokenExpiredDestination);
        break;

      default:
        console.error("Unsupported method:", method);
        return;
    }

    return response;
  } catch (error) {
    clearItems();
    return error
  }
};

const handleResponse = (response, tokenExpiredDestination) => {
  if (response.status === 401) {
    // router.push(tokenExpiredDestination);
  }
};

const clearItems = () => {
  localStorage.removeItem("dataToCompare");
};

export default makeApiRequest;
