<template>
  <section class="slider__main" :class="{ slider__animation: animation }">
    <Carousel
      ref="Carousel"
      v-if="mainOffer[0] && mainOffer[0].photos.length > 0"
    >
      <Slide
        v-if="mainOffer[0]"
        v-for="(photo, index) in mainOffer[0].photos"
        :key="index"
      >
        <div class="slider__wrapper--gallery">
          <svg
            class="slider__icon gallery__prev--icon"
            width="23px"
            height="40px"
            @click="prevImage()"
          >
            <image
              v-if="
                mainOffer[0] &&
                mainOffer[0].photos.length > 0 &&
                currentIndex !== 0
              "
              href="../../assets/Icons/arrow_left.svg"
              alt="Previous Image"
            />
          </svg>
          <div class="slider__main--image">
            <img :src="photo" />
          </div>
          <svg
            class="slider__icon gallery__next--icon"
            width="23px"
            height="40px"
            @click="nextImage()"
          >
            <image
              v-if="
                mainOffer[0] &&
                mainOffer[0].photos.length > 0 &&
                currentIndex < mainOffer[0].photos.length - 1
              "
              href="../../assets/Icons/arrow_right.svg"
              alt="Next Image"
            />
          </svg>
        </div>
      </Slide>
    </Carousel>
    <div
      class="slider__thumbnail--relative"
      v-if="mainOffer[0] && mainOffer[0].photos.length > 0"
    >
      <div class="slider__thumbnail">
        <ul class="slider__thumbnail--list" ref="thumbnailsContainer">
          <li
            v-if="mainOffer[0]"
            class="slider__thumbnail--element"
            v-for="(thumbnail, index) in mainOffer[0].photos"
            :key="index"
            :class="{
              active__thumbnail: currentIndex === index && returnColor === '',
              'active__thumbnail--bordo':
                currentIndex === index && returnColor === '#801C62',
            }"
          >
            <img
              :src="thumbnail"
              width="121"
              height="68"
              @click="showImage(index)"
              :class="{
                'active__thumbnail--image':
                  currentIndex === index && returnColor === '',
                'active__thumbnail--bordo--image':
                  currentIndex === index && returnColor === '#801C62',
              }"
            />
          </li>
        </ul>
      </div>
      <svg
        v-if="children && children > 8"
        class="slider__thumbnail--relative-prev"
        width="17px"
        height="30px"
        @click="slider('left')"
      >
        <image href="../../assets/Icons/arrow_left.svg" alt="Prev Image" />
      </svg>
      <svg
        v-if="children && children > 8"
        class="slider__thumbnail--relative-next"
        width="17px"
        height="30px"
        @click="slider('right')"
      >
        <image href="../../assets/Icons/arrow_right.svg" alt="Next Image" />
      </svg>
    </div>
    <img
      v-if="
        returnGalleryImage === 'ics-gallery-pic.webp' &&
        mainOffer[0].photos.length < 1
      "
      src="../../assets/Images/ics-gallery-pic.webp"
      class="slider__placeholder--image"
    />
    <img
      v-if="returnGalleryImage === '' && mainOffer[0].photos.length < 1"
      src="../../assets/Images/no-pics.webp"
      class="slider__placeholder--image"
    />
  </section>
</template>

<script>
import { useStore } from "../../store";
import Slider from "@vueform/slider";
import { isInViewport } from "../../plugins/helpers";
import httpActions from "../../plugins/httpActionService";
import ClipLoader from "vue-spinner/src/ClipLoader.vue";
import "vue3-carousel/dist/carousel.css";
import { Carousel, Slide } from "vue3-carousel";

export default {
  components: {
    Slider,
    ClipLoader,
    Carousel,
    Slide,
  },
  data() {
    return {
      mainImage: null,
      currentIndex: 0,
      dragging: false,
      offsetX: 0,
      sliderValue: 0,
      animation: false,
      section: "gtjyq9",
      isInView: false,
      isInViewFlag: false,
      allowToTrack: false,
      allowToTrackFlag: false,
      loaded: false,
      translate: 0,
      children: null,
    };
  },
  computed: {
    mainOffer() {
      return useStore().getMainOffer;
    },
    returnGalleryImage() {
      return useStore().getGalleryImage;
    },
    returnColor() {
      return useStore().getColor;
    },
  },
  methods: {
    showImage(index) {
      const slider = this.$refs.Carousel;
      if (slider) {
        slider.slideTo(index);
        this.currentIndex = index;
      }
    },
    allowToShowImage() {
      this.loaded = true;
    },
    slider(direction) {
      const slider = this.$refs.thumbnailsContainer;
      const numberOfElements = 8;
      const gap = 20;
      let n;
      if (this.children < 16) {
        n = 0;
      } else {
        n = Math.floor(this.children / 10);
      }
      const elementWidth = 121;
      const modulo = this.children % numberOfElements;
      const elements = numberOfElements * n + modulo;
      const totalCount = -(elements * (gap + elementWidth));
      if (direction === "left" && this.translate < 0) {
        this.translate = this.translate + slider.offsetWidth + gap;
        if (this.translate > 0) {
          this.translate = 0;
        }
        slider.style.transform = `translateX(${this.translate}px)`;
      } else if (direction === "right") {
        this.translate = this.translate - (slider.offsetWidth + gap);
        if (this.translate < totalCount) {
          this.translate = totalCount;
        }
        slider.style.transform = `translateX(${this.translate}px)`;
      }
    },
    nextImage() {
      const slider = this.$refs.Carousel;
      slider.next();
      this.currentIndex = slider.data.currentSlide.value;
    },
    prevImage() {
      const slider = this.$refs.Carousel;
      slider.prev();
      this.currentIndex = slider.data.currentSlide.value;
    },
    checkViewport() {
      const element = this.$refs.trackComponent;
      if (element) {
        this.isInView = isInViewport(element);
        if (this.isInView && !this.isInViewFlag) {
          this.isInViewFlag = true;
          this.allowToTrack = true;
          setTimeout(() => {
            if (this.allowToTrack && this.allowToTrackFlag) {
              const arr = [];
              arr.push(useStore().mainOffer[0].id);
              httpActions.trackSection(this.section, arr);
            }
            this.allowToTrackFlag = false;
            this.isInViewFlag = false;
          }, 1500);
        }
        if (this.isInView === false) {
          this.allowToTrack = false;
        }
      }
    },
  },
  mounted() {
    this.animation = true;
    this.checkViewport();
    window.addEventListener("scroll", this.checkViewport);
    this.$nextTick(() => {
      if (this.$refs.thumbnailsContainer) {
        const slider = this.$refs.thumbnailsContainer;
        this.children = slider.children.length;
      }
    });
    setInterval(() => {
      if (this.$refs.Carousel && this.$refs.Carousel.data) {
        this.showImage(this.$refs.Carousel.data.currentSlide.value);
      }
    }, 500);
  },
  beforeUnmount() {
    window.removeEventListener("scroll", this.checkViewport);
  },
  watch: {
    mainOffer(newValue) {
      if (newValue && this.$refs.thumbnailsContainer) {
        const slider = this.$refs.thumbnailsContainer;
        this.animation = false;
        this.allowToTrackFlag = true;
        this.translate = 0;
        slider.style.transform = `translateX(0px)`;
        this.checkViewport();
        setTimeout(() => {
          this.animation = true;
          this.children = slider.children.length;
        }, 100);
      }
    },
    isInView(newVal) {
      if (newVal) {
        this.allowToTrackFlag = true;
      }
    },
  },
};
</script>

<style src="@vueform/slider/themes/default.css"></style>
