<template>
  <section class="financing" ref="financingComponent" :class="{'financing__animation' : animation}">
    <div class="financing__blur--left"></div>
    <div class="financing__blur--right"></div>
    <HeadingGray :HeadingText="$t('financingOffer')"></HeadingGray>
    <HeadingBlack
      v-if="screen"
      :HeadingTextBlack="$t('financingOfferTextMobile')"
    ></HeadingBlack>
    <HeadingBlack v-else :HeadingTextBlack="$t('financingOfferText')"></HeadingBlack>
    <FinancingOptions></FinancingOptions>
    <FinancingForm></FinancingForm>
  </section>
</template>

<script>
import HeadingGray from "../Header/HeadingGray.vue";
import HeadingBlack from "../Header/HeadingBlack.vue";
import FinancingOptions from "../Financing/FinancingOptions.vue";
import FinancingForm from "./FinancingForm.vue";
import { useStore } from "../../store";

export default {
  components: {
    HeadingGray,
    HeadingBlack,
    FinancingOptions,
    FinancingForm,
  },
  props: {
    screen: Boolean,
  },
  computed: {
    mainOffer() {
      return useStore().getMainOffer;
    }
  },
  data() {
    return {
      animation: false,
    }
  },
  mounted() {
    if (useStore().financingScroll) {
      this.$refs.financingComponent.scrollIntoView({ behavior: 'smooth' });
      useStore().resetScrollAction(false);
    }
    this.animation = true
  },
  watch: {
    mainOffer(newValue) {
      if (newValue) {
        this.animation = false;
        setTimeout(() => {
          this.animation = true;
        }, 100);
      }
    }
  }
};
</script>
