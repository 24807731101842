<template>
  <div v-if="allowToLoad && !noOffersCommunicate">
    <div v-if="showPreview" class="main__preview">Podgląd aplikacji</div>
    <div class="main">
      <div class="sidebar__wrapper" :class="{ 'blur-isOpen': this.menuIsOpen }">
        <sideBar-mobile
          v-if="isScreenBelow980px"
          @menuIsOpen="allowToBlur"
        ></sideBar-mobile>
        <sideBar v-else></sideBar>
      </div>
      <Header></Header>
      <div v-if="filesButtonClicked">
        <your-files :screen="isScreenBelow980px" :allFiles="true"></your-files>
      </div>
      <slider v-if="!filesButtonClicked"></slider>
      <configuration
        v-if="!filesButtonClicked"
        :screen="isScreenBelow980px"
      ></configuration>
      <specials v-if="!filesButtonClicked && hideSpecials !== '2'"></specials>
      <!-- <accesorries></accesorries> -->
      <!-- <financing :screen="isScreenBelow980px"></financing> -->
      <your-files
        v-if="!filesButtonClicked"
        :screen="isScreenBelow980px"
        :allFiles="false"
      ></your-files>
      <p class="adnotation__text">
        Niniejsze ogłoszenie jest wyłącznie informacją handlową i nie stanowi
        oferty w myśl art.66 par.1 Kodeksu Cywilnego. Sprzedający nie odpowiada
        za ewentualne błędy lub nieaktualności ogłoszenia.
      </p>
      <Footer></Footer>
    </div>
  </div>
  <div v-if="!allowToLoad && !noOffersCommunicate" class="loader__container">
    <pulseLoader :size="loaderSize" :color="loaderColor"></pulseLoader>
  </div>
  <p v-if="!allowToLoad && noOffersCommunicate" class="main__text">
    Twoja oferta wygasła, skontaktuj się z przedstawicielem dealera, aby uzyskać
    ponowny dostęp lub nową ofertę
  </p>
</template>

<script>
import SideBarMobile from "../SideBar/SideBarMobile.vue";
import SideBar from "../SideBar/SideBar.vue";
import Header from "../Header/Header.vue";
import Slider from "../Slider/Slider.vue";
import Configuration from "../Configuration/Configuration.vue";
import Specials from "../Specials/Specials.vue";
import Accesorries from "../Accesorries/Accesorries.vue";
import Financing from "../Financing/Financing.vue";
import YourFiles from "../YourFiles/YourFiles.vue";
import Footer from "../Footer/Footer.vue";
import PulseLoader from "vue-spinner/src/PulseLoader.vue";
import { useStore } from "../../store";
import httpActions from "../../plugins/httpActionService";
import dataToCompare from "../../plugins/dataToCompare";

export default {
  components: {
    SideBarMobile,
    SideBar,
    Header,
    Slider,
    Configuration,
    Specials,
    Accesorries,
    Financing,
    YourFiles,
    Footer,
    PulseLoader,
  },
  data() {
    return {
      loaderSize: "60px",
      loaderColor: "black",
      isScreenBelow980px: window.innerWidth < 980,
      menuIsOpen: false,
      allowToLoad: false,
      start: false,
      prevPath: null,
    };
  },
  computed: {
    dataIsLoaded() {
      return useStore().dataLoaded;
    },
    filesButtonClicked() {
      return useStore().filesButtonClicked;
    },
    hideSpecials() {
      return useStore().cscClientId;
    },
    noOffersCommunicate() {
      return useStore().noOffersCommunicate;
    },
    showPreview() {
      if (useStore().query) {
        return true;
      } else {
        return false;
      }
    },
  },
  methods: {
    checkScreenWidth() {
      this.isScreenBelow980px = window.innerWidth < 980;
    },
    allowToBlur(newValue) {
      this.menuIsOpen = newValue;
    },
  },
  mounted() {
    httpActions.getAssets();
    this.checkScreenWidth();
    window.addEventListener("resize", this.checkScreenWidth);
    if (!useStore().loginInProcess) {
      httpActions.tokenActive("/main", "/login", useStore().query);
    }
    dataToCompare.data = [];
    localStorage.removeItem("dataToCompare");
  },
  beforeUnmount() {
    window.removeEventListener("resize", this.checkScreenWidth);
  },
  watch: {
    dataIsLoaded(newValue) {
      if (newValue) {
        this.allowToLoad = true;
        localStorage.setItem("lastSeenOffer", useStore().defaultOfferID);
        setInterval(() => {
          httpActions.postEvent();
        }, 5000);
      }
    },
  },
};
</script>
