<template>
  <p class="yourfiles__text">
    {{$t('shown')}} {{ shownRows }} {{$t('outOf')}} {{ allRows }} {{$t('position')}}
  </p>
  <ButtonGray
    v-if="!allIsShown"
    :ButtonText="$t('showMore')"
    @click="showMoreClicked"
  ></ButtonGray>
  <p
    v-if="!allIsShown"
    class="yourfiles__text underline"
    @click="showAllClicked"
  >
    {{$t('showAll')}}
  </p>
</template>

<script>
import ButtonGray from "../Button/ButtonGray.vue";

export default {
  components: {
    ButtonGray,
  },
  props: {
    mountedComponent: Boolean,
    allRows: Number,
    shownRows: Number,
  },
  data() {
    return {
      showMoreIsClicked: true,
      showAllIsClicked: true,
      allIsShown: false,
    };
  },
  emits: ["showMoreClicked", "showAllClicked"],
  methods: {
    showMoreClicked() {
      this.$emit("showMoreClicked", this.showMoreIsClicked);
      this.$nextTick(() => {
        if (this.shownRows >= this.allRows) {
          this.allIsShown = true;
        }
      });
    },
    showAllClicked() {
      this.$emit("showAllClicked", this.showAllIsClicked);
      this.$nextTick(() => {
        if (this.shownRows >= this.allRows) {
          this.allIsShown = true;
        }
      });
    },
  },
  mounted() {
    this.$nextTick(() => {
      if (this.shownRows >= this.allRows) {
        this.allIsShown = true;
      }
    });
  },
};
</script>
