<template>
  <div v-if="allColumns" class="configuration__container--mobile">
    <div
      class="configuration__block--mobile"
      v-for="(equipment, index) in equipmentData"
      :key="index"
    >
      <div class="configuration__wrapper--mobile">
        <p class="configuration__header--mobile">{{ equipment.id }}</p>
        <p class="configuration__text--mobile">
          {{ capitalizeFirstLetter(equipment.name) }}
        </p>
      </div>
      <p
        v-if="equipment.price === '0.00'"
        class="configuration__text--mobile-price"
      >
        -
      </p>
      <p v-else class="configuration__text--mobile-price">
        {{ formatPrice(equipment.price, true) }} {{ $t("currency") }}
      </p>
    </div>
  </div>
  <div v-else class="configuration__container--mobile">
    <div
      class="configuration__block--mobile"
      v-for="(equipment, index) in equipmentData"
      :key="index"
    >
      <div class="configuration__wrapper--mobile">
        <p class="configuration__header--mobile"></p>
        <p class="configuration__text--mobile">
          {{ capitalizeFirstLetter(equipment.name) }}
        </p>
      </div>
      <p class="configuration__text--mobile-price">-</p>
    </div>
  </div>
</template>

<script>
import ShowMore from "../YourFiles/ShowMore.vue";
import { useStore } from "../../store";
import { formatPrice, capitalizeFirstLetter } from "../../plugins/helpers";

export default {
  data() {
    return {
      equipmentData: "",
      mountedComponent: false,
      allRows: 0,
      shownRows: 0,
      showAllIsClicked: false,
      showMoreIsClicked: false,
      allColumns: true,
    };
  },
  computed: {
    mainOffer() {
      return useStore().getMainOffer;
    },
  },
  components: {
    ShowMore,
  },
  methods: {
    formatPrice,
    capitalizeFirstLetter,
    getData() {
      const data = JSON.parse(useStore().mainOffer[0].equipment);
      this.equipmentData = JSON.parse(data);
      this.mountedComponent = true;
      if (this.equipmentData[0] && this.equipmentData[0].id) {
        this.equipmentData[0].id === ""
          ? (this.allColumns = false)
          : (this.allColumns = true);
      }
    },

    hideRows() {
      const tableRows = document.querySelectorAll(
        ".configuration__block--mobile"
      );

      if (tableRows.length > 5) {
        for (
          let i = tableRows.length - (tableRows.length - 5);
          i < tableRows.length;
          i++
        ) {
          tableRows[i].classList.add("no-display");
        }
      }
    },

    countAllRows() {
      const tableRows = document.querySelectorAll(
        ".configuration__block--mobile"
      );
      this.allRows = tableRows.length;
    },

    countShownRows() {
      const tableRows = document.querySelectorAll(
        ".configuration__block--mobile"
      );

      for (let i = 0; i < tableRows.length; i++) {
        if (tableRows[i].classList.contains("no-display")) {
          return;
        } else {
          this.shownRows++;
        }
      }
    },

    showMoreClicked(newValue) {
      this.showMoreIsClicked = newValue;
    },

    showAllClicked(newValue) {
      this.showAllIsClicked = newValue;
    },

    showAllRows() {
      const tableRows = document.querySelectorAll(
        ".configuration__block--mobile"
      );

      for (let i = 0; i < tableRows.length; i++) {
        if (tableRows[i].classList.contains("no-display")) {
          tableRows[i].classList.remove("no-display");
        }
      }

      this.shownRows = this.allRows;
    },

    showMoreRows() {
      const tableRows = document.querySelectorAll(
        ".configuration__block--mobile"
      );
      let visibleRowCounter = 0;

      for (let i = 0; i < tableRows.length; i++) {
        if (tableRows[i].classList.contains("no-display")) {
          tableRows[i].classList.remove("no-display");
          visibleRowCounter++;
        }
        if (visibleRowCounter >= 5) {
          break;
        }
      }

      this.shownRows = this.shownRows + 5;
      if (this.shownRows > this.allRows) {
        this.shownRows = this.allRows;
      }
    },
  },
  mounted() {
    this.getData();
  },

  watch: {
    mountedComponent: function (newValue, oldValue) {
      if (newValue !== oldValue) {
        this.hideRows();
        this.countAllRows();
        this.countShownRows();
        this.getData();
      }
    },

    showAllIsClicked: function (newValue, oldValue) {
      if (newValue !== oldValue) {
        this.showAllRows();
      }
    },

    showMoreIsClicked: function (newValue, oldValue) {
      if (newValue !== oldValue) {
        this.showMoreRows();
      }
    },

    mainOffer(newVal) {
      if (newVal) {
        this.getData();
      }
    },
  },
};
</script>
