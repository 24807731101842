export const formatPrice = (string, flag) => {
  const value = Number(string);
  if (!isNaN(value)) {
    const stringValue = Math.floor(value).toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
    
    if (flag) {
      const decimalPart = value.toFixed(2).split('.')[1];
      return `${stringValue},${decimalPart}`;
    } else {
      return stringValue;
    }
  } else {
    return string;
  }
};

export const formatDate = (date) => {
  const dateObject = new Date(date);
  const day = dateObject.getDate();
  const month = dateObject.getMonth() + 1;
  const year = dateObject.getFullYear();

  const formattedDate = `${day < 10 ? "0" : ""}${day}.${
    month < 10 ? "0" : ""
  }${month}.${year}`;

  return formattedDate;
};

export const descShortage = (string) => {
  if (string && string.length > 100) {
    return string.slice(0, 97) + "...";
  } else {
    return string;
  }
};

export const capitalizeFirstLetter = (string) => {
  if (!string) return string;
  return string.charAt(0).toUpperCase() + string.slice(1);
};

export const formatMileage = (input) => {
  const str = String(input);

  if (str.length > 3) {
      const lastThree = str.slice(-3);
      const rest = str.slice(0, -3);
      return `${rest} ${lastThree}`;
  }

  return str;
}

export const isInViewport = (element) => {
  const rect = element.getBoundingClientRect();
  return (
    rect.top >= 0 &&
    rect.left >= 0 &&
    rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
    rect.right <= (window.innerWidth || document.documentElement.clientWidth)
  )
}

export const isInViewportBiggerSection = (element) => {
  const rect = element.getBoundingClientRect();
  return (
    (rect.top >= -1145 && rect.top <= 455) &&
    rect.left >= 0 &&
    rect.right <= (window.innerWidth || document.documentElement.clientWidth)
  )
}

export const isOnTop = (element) => {
  const rect = element.getBoundingClientRect();
  if (rect.top === 0) {
    return true
  }
}

export const lastDigitReplace = (str) => {
  if (!isNaN(parseInt(str[str.length - 1]))) {
        const lastDigit = parseInt(str[str.length - 1]);
        const newDigit = lastDigit + 1;
        return str.slice(0, -1) + newDigit;
    } else {
        return str + "-1";
    }
}