<template>
  <table
    v-if="allColumns"
    class="configuration__table"
    cellspacing="0"
    ref="trackComponent"
  >
    <tr class="table__row">
      <td class="table__data" v-for="header in tableHeaders" :key="header">
        {{ header }}
      </td>
    </tr>
    <tr
      class="table__row"
      v-for="(equipment, index) in equipmentData"
      :key="index"
    >
      <td
        class="table__data"
        :class="{ 'table__data--bold': equipment.hasPackageOptions }"
      >
        {{ equipment.id }}
      </td>
      <td
        class="table__data"
        :class="{ 'table__data--bold': equipment.hasPackageOptions }"
      >
        {{ capitalizeFirstLetter(equipment.name) }}
      </td>
      <td v-if="equipment.price === '0.00'" class="table__data">-</td>
      <td v-else class="table__data">
        {{ formatPrice(equipment.price, true) }} {{ $t("currency") }}
      </td>
    </tr>
  </table>
  <table
    v-else
    class="configuration__table"
    cellspacing="0"
    ref="trackComponent"
  >
    <tr class="table__row">
      <td
        class="table__data"
        v-for="header in tableHeadersShortage"
        :key="header"
      >
        {{ header }}
      </td>
    </tr>
    <tr
      class="table__row"
      v-for="(equipment, index) in equipmentData"
      :key="index"
    >
      <td
        class="table__data"
        :class="{ 'table__data--bold': equipment.hasPackageOptions }"
      >
        {{ equipment.id }}
      </td>
      <td
        class="table__data"
        :class="{ 'table__data--bold': equipment.hasPackageOptions }"
      >
        {{ capitalizeFirstLetter(equipment.name) }}
      </td>
      <td class="table__data">-</td>
    </tr>
  </table>
</template>

<script>
import { useStore } from "../../store";
import { formatPrice } from "../../plugins/helpers";
import {
  capitalizeFirstLetter,
  isInViewportBiggerSection,
} from "../../plugins/helpers";
import httpActions from "../../plugins/httpActionService";

export default {
  data() {
    return {
      tableHeaders: ["ID", "Nazwa", "Wartość"],
      tableHeadersShortage: ["", "Nazwa", ""],
      equipmentData: "",
      section: "x73onf",
      isInView: false,
      isInViewFlag: false,
      allowToTrack: false,
      allowToTrackFlag: false,
      allColumns: true,
    };
  },
  computed: {
    mainOffer() {
      return useStore().getMainOffer;
    },
  },
  methods: {
    formatPrice,
    capitalizeFirstLetter,
    getData() {
      if (useStore().mainOffer[0]) {
        const data = JSON.parse(useStore().mainOffer[0].equipment);
        this.equipmentData = JSON.parse(data);
        if (this.equipmentData[0] && this.equipmentData[0].id) {
          this.equipmentData[0].id === ""
            ? (this.allColumns = false)
            : (this.allColumns = true);
        }
      }
    },
    checkViewport() {
      const element = this.$refs.trackComponent;
      if (element) {
        this.isInView = isInViewportBiggerSection(element);
        if (this.isInView && !this.isInViewFlag) {
          this.isInViewFlag = true;
          this.allowToTrack = true;
          setTimeout(() => {
            if (this.allowToTrack && this.allowToTrackFlag) {
              const arr = [];
              arr.push(useStore().mainOffer[0].id);
              httpActions.trackSection(this.section, arr);
            }
            this.allowToTrackFlag = false;
            this.isInViewFlag = false;
          }, 1500);
        }
        if (this.isInView === false) {
          this.allowToTrack = false;
        }
      }
    },
  },
  mounted() {
    this.getData();
    this.checkViewport();
    window.addEventListener("scroll", this.checkViewport);
  },
  beforeUnmount() {
    window.removeEventListener("scroll", this.checkViewport);
  },
  watch: {
    mainOffer(newValue) {
      if (newValue) {
        this.getData();
        this.allowToTrackFlag = true;
        this.checkViewport();
      }
    },
    isInView(newVal) {
      if (newVal) {
        this.allowToTrackFlag = true;
      }
    },
  },
};
</script>
