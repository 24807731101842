{
  "accessorriesHeader": "Akcesoria dla Twojego samochodu",
  "accessorriesTextBlack": "Top 10 akcesoriów dla modelu CLA",
  "compareOffers": "Porównaj oferty",
  "financingOffer": "Oferta finansowania",
  "currency": "zł",
  "catalogPrice": "Cena katalogowa: ",
  "equipmentList": "Lista wyposażenia",
  "yourConfiguration": "Twoja konfiguracja",
  "financingOfferText": "Jesteś zainteresowany finansowaniem? Wybierz jeden z dostępnych produktów finansowych oraz wypełnij krótki formularz.",
  "financingOfferTextMobile": "Jesteś zainteresowany finansowaniem? Wybierz jeden z dostępnych produktów finansowych.",
  "entranceFee": "Wpłata wstępna",
  "financingPeriod": "Okres finansowania",
  "preferredInstallmentAmount": "Preferowana kwota raty",
  "netMonth": "netto/mies",
  "sendProposition": "Wyślij propozycję raty",
  "choose": "Wybierz",
  "type": "Wpisz",
  "login": "Zaloguj się",
  "step": "Krok",
  "outOf": "z",
  "loginText": "Poznaj szczegóły przygotowanej specjalnie dla Ciebie oferty",
  "numberType": "Wpisz numer telefonu lub adres e-mail",
  "next": "Dalej",
  "loginConfirm": "Potwierdzenie logowania",
  "numberAuth": "Na podany numer telefonu lub adres e-mail wysłaliśmy 6-cio cyfrowy kod uwierzytelniający. Wpisz go w pole poniżej",
  "sendAgain": "Wyślij kod ponownie",
  "sixDigits": "6-cio cyfrowy kod",
  "offerExpiration": "Oferta wygasa za",
  "compareMatched": "Porównaj zaznaczone",
  "call": "Zadzwoń",
  "contactWithConsultant": "Skontaktuj się ze swoim Doradcą",
  "yourNewCar": "Twój nowy samochód",
  "specification": "Specyfikacja",
  "specials": "Pakiety specjalne",
  "accessorries": "Akcesoria",
  "financing": "Finansowanie",
  "yourOffers": "Twoje oferty",
  "videoPresentation": "Wideo prezentacja",
  "gallery": "Galeria",
  "offerForYou": "Oferta dla Ciebie: ",
  "specialsHeader": "Pakiety specjalne w Twoim samochodzie",
  "gasCard": "Karta paliwowa o wartości 6 000 zł",
  "insurance": "Ubezpieczenie AC/OC na rok",
  "audioVisual": "Oprawa Audio-wizualna przy odbiorze samochodu",
  "shown": "Pokazano",
  "position": "pozycji",
  "showAll": "Pokaż wszystkie",
  "showMore": "Pokaż więcej",
  "yourFilesHeader": "Twoje pliki",
  "yourFilesText": "Tu znajdziesz oferty, które są odpowiedzią na Twoją prośbę",
  "name": "Typ pliku",
  "commentary": "Opis",
  "exposeDate": "Data wystawienia",
  "wrongLogin": "Nieprawidłowy numer lub adres e-mail",
  "wrongPassword": "Nieprawidłowe hasło",
  "tooManyAttempts": "Zbyt wiele prób logowania, proszę poczekać"
}
