<template>
  <div
    class="login__second--container"
    :class="{
      'login__second--container-transform': this.loginContainerTransform,
    }"
    ref="elementToShow"
  >
    <p class="login__text--confirm">{{ $t("loginConfirm") }}</p>
    <p class="login__description">
      {{ $t("numberAuth") }}
    </p>
    <div
      class="login__helper"
      :class="{ 'login__helper-transform': sendAgainShow }"
    >
      <p
        class="login__send--again"
        :class="{ 'login__sendAgain-transform': this.sendAgainShow }"
        ref="slideTextElement"
        @click="sendCodeAgain"
      >
        {{ $t("sendAgain") }}
      </p>
      <p class="login__label--confirm" ref="loginTextConfirm"></p>
      <label
        class="login__label--confirm"
        :class="{ 'login__sendAgain-transform': this.sendAgainShow }"
        ref="slideLabelElement"
        >{{ $t("sixDigits") }}
        <div class="login__input--container">
          <input
            class="login__input--digit"
            type="number"
            inputmode="numeric"
            pattern="[0-9]"
            :disabled="loader"
            @input="moveFocus($event, 1)"
            ref="inputDigit"
          />
          <input
            class="login__input--digit"
            maxlength="1"
            type="number"
            inputmode="numeric"
            pattern="[0-9]"
            :disabled="loader"
            @input="moveFocus($event, 2)"
            @keydown.backspace="moveFocusWhenBackspace($event, 2)"
          />
          <input
            class="login__input--digit"
            maxlength="1"
            type="number"
            inputmode="numeric"
            pattern="[0-9]"
            :disabled="loader"
            @input="moveFocus($event, 3)"
            @keydown.backspace="moveFocusWhenBackspace($event, 3)"
          />
          <input
            class="login__input--digit"
            maxlength="1"
            type="number"
            inputmode="numeric"
            pattern="[0-9]"
            :disabled="loader"
            @input="moveFocus($event, 4)"
            @keydown.backspace="moveFocusWhenBackspace($event, 4)"
          />
          <input
            class="login__input--digit"
            maxlength="1"
            type="number"
            inputmode="numeric"
            pattern="[0-9]"
            :disabled="loader"
            @input="moveFocus($event, 5)"
            @keydown.backspace="moveFocusWhenBackspace($event, 5)"
          />
          <input
            class="login__input--digit"
            maxlength="1"
            type="number"
            inputmode="numeric"
            :disabled="loader"
            @input="sliceNumber($event, 6)"
            @keyup.enter="checkTokenAndGoMain"
            @keydown.backspace="moveFocusWhenBackspace($event, 6)"
            ref="lastInput"
          />
        </div>
      </label>
      <label v-if="getError" class="login__label--error" :style="transformErrorText">
        {{ $t("wrongPassword") }}
      </label>
      <svg
        v-if="step === 2"
        class="csc__logo"
        :class="{ 'csc__logo-transform': sendAgainShow }"
      >
        <image href="../../assets/Icons/csc_logo_RGB.svg"></image>
      </svg>
    </div>
  </div>
</template>

<script>
import { useStore } from "../../store";
import httpActions from "../../plugins/httpActionService";
import ClipLoader from "vue-spinner/src/ClipLoader.vue";

export default {
  components: {
    ClipLoader,
  },
  data() {
    return {
      step: 2,
      sendAgainShow: false,
      digitInputs: new Array(6).fill(""),
      allowSendCodeAgain: true,
      fillArray: [],
      pasteExecuted: false,
      loginContainerTransform: false,
      isPasswordProcessing: false,
      loader: false,
    };
  },
  computed: {
    getError() {
      return useStore().passwordError;
    },
    transformErrorText() {
      if (this.sendAgainShow) {
        return {
          "transform": "translateY(0px)"
        }
      } else {
        return {
          "transform": "translateY(-45px)"
        }
      }
    }
  },
  methods: {
    sliceNumber(event) {
      this.$refs.lastInput.value = String(this.$refs.lastInput.value).slice(
        0,
        1
      );
      this.digitInputs[5] = event.target.value;
      const enteredPassword = this.digitInputs.join("");
      if (enteredPassword.length === 6) {
        useStore().set("enteredPassword", enteredPassword);
        this.loader = true;
        setTimeout(() => {
          this.checkTokenAndGoMain();
          this.loader = false;
        }, 2000);
      }
    },
    moveFocus(event, currentInput) {
      this.pasteWholeCode(event, currentInput);
      if (event.target.value.length === 1 && !this.pasteExecuted) {
        if (currentInput < 6) {
          const nextInputSelector = `.login__input--digit:nth-child(${
            currentInput + 1
          })`;
          const nextInputField = this.$el.querySelector(nextInputSelector);
          if (nextInputField) {
            nextInputField.focus();
          }
        } else {
        }
      }
      this.digitInputs[currentInput - 1] = event.target.value;
    },

    pasteWholeCode(event, currentInput) {
      if (currentInput === 1) {
        const enteredCode = event.target.value;
        const enteredCodeSplit = enteredCode.toString().split("");

        for (let i = 0; i < 6; i++) {
          this.digitInputs[i] = enteredCodeSplit[i];
        }

        const enteredPassword = this.digitInputs.join("");
        if (enteredPassword.length === 6) {
          useStore().set("enteredPassword", enteredPassword);
        }

        this.fillArray = enteredCodeSplit.map(Number);
        const firstInputSelector = `.login__input--digit:nth-child(${currentInput})`;
        const firstInputField = this.$el.querySelector(firstInputSelector);

        if (enteredCode.length === 6 && /^\d+$/.test(enteredCode)) {
          for (let i = 0; i <= 6; i++) {
            const nextInputSelector = `.login__input--digit:nth-child(${i})`;
            const nextInputField = this.$el.querySelector(nextInputSelector);

            if (i === 6) {
              if (nextInputField) {
                nextInputField.focus();
              }
            }

            firstInputField.value = this.fillArray[0];
            if (i > 0 && nextInputField) {
              nextInputField.value = this.fillArray[i - 1];
            }
          }
          this.pasteExecuted = true;
          this.loader = true;
          setTimeout(() => {
            this.checkTokenAndGoMain();
            this.loader = false;
          }, 2000);
        } else if (
          (enteredCode.length > 1 && enteredCode.length < 6) ||
          enteredCode.length > 6 ||
          !/^\d+$/.test(enteredCode)
        ) {
          firstInputField.value = "";
        }
      }
    },
    moveFocusWhenBackspace(event, currentInput) {
      this.pasteExecuted = false;
      if (event.target.value === "") {
        const previousInputSelector = `.login__input--digit:nth-child(${
          currentInput - 1
        })`;
        const previousInputField = this.$el.querySelector(
          previousInputSelector
        );
        if (previousInputField) {
          previousInputField.focus();
        }
      }
    },
    async sendCodeAgain() {
      const text = this.$refs.loginTextConfirm;
      if (this.allowSendCodeAgain === true) {
        httpActions.enteredPhoneNumber();
        this.allowSendCodeAgain = false;
        this.sendAgainShow = false;
        text.textContent = "Wysłano";
        setTimeout(() => {
          this.allowSendCodeAgain = true;
          this.sendAgainShow = true;
          text.textContent = "";
        }, 120000);
      }
    },

    showSendAgain() {
      setTimeout(() => {
        this.sendAgainShow = true;
      }, 120000);
    },

    async checkTokenAndGoMain() {
      this.checkExpirationDate();
      if (!this.isPasswordProcessing) {
        this.isPasswordProcessing = true;
        await httpActions.enteredPassword();
        this.isPasswordProcessing = false;
      }
    },

    checkExpirationDate() {
      const expirationDate = new Date(useStore().passwordExpirationDate);
      const checkExpirationInterval = setInterval(() => {
        const currentTime = new Date();
        if (currentTime > expirationDate) {
          this.phoneNumberIsValid = false;
          this.step = 1;
          useStore().password = "";
          useStore().phoneNumber = "";
          clearInterval(checkExpirationInterval);
          return;
        }
      }, 1000);
    },
  },
  mounted() {
    this.showSendAgain();
    this.$nextTick(() => {
      this.loginContainerTransform = true;
      this.$refs.inputDigit.focus();
    });
    this.$emit("stepChange", this.step);
  },
  beforeUnmount() {
    this.loginContainerTransform = false;
  },
};
</script>
