<template>
  <form class="financing__form" @submit="getFormData" ref="form">
    <div class="financing__wrapper">
      <div class="financing__form--container">
        <p class="financing__text--form">{{$t('entranceFee')}}</p>
        <Multiselect
          class="financing__select"
          v-model="paymentValue"
          :options="paymentOptions"
          :placeholder="$t('choose')"
        />
      </div>
      <div class="financing__form--container">
        <p class="financing__text--form">{{$t('financingPeriod')}}</p>
        <Multiselect
          class="financing__select"
          v-model="periodValue"
          :options="periodOptions"
          :placeholder="$t('choose')"
        />
      </div>
      <div class="financing__form--container">
        <p class="financing__text--form">{{$t('preferredInstallmentAmount')}}</p>
        <label class="financing__label" :data-label="$t('currency') + ' ' + $t('netMonth')">
          <input
            type="text"
            class="financing__input"
            :placeholder="$t('choose')"
            maxLength="6"
            v-model="creditValue"
          />
        </label>
      </div>
    </div>
    <ButtonGray :ButtonText="$t('sendProposition')"></ButtonGray>
  </form>
</template>

<script>
import ButtonGray from "../Button/ButtonGray.vue";
import Multiselect from "@vueform/multiselect";
import { useStore } from "../../store";
import Notiflix from "notiflix";

export default {
  components: {
    ButtonGray,
    Multiselect,
  },
  data() {
    return {
      paymentValue: null,
      periodValue: null,
      paymentOptions: ["10%", "15%", "20%", "25%", "30%"],
      periodOptions: ["24 miesiące", "36 miesięcy", "48 miesięcy"],
      creditValue: '',
    };
  },
  methods: {
    getFormData(event) {
      event.preventDefault();
      const jsonData = {
        paymentValue: this.paymentValue,
        periodValue: this.periodValue,
        creditValue: this.creditValue,
        selectedProduct: useStore().selectedProduct,
      };

      for (const key in jsonData) {
        if (jsonData[key] === null || jsonData[key] === '' ) {
          Notiflix.Notify.warning(`Nie zaznaczono opcji ${key}`);
          return;
        }
      }

      this.$refs.form.submit();
    },
  },
};
</script>

<style src="@vueform/multiselect/themes/default.css"></style>