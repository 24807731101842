import { defineStore } from "pinia";
import { setBlockTracking } from "vue";

export const useStore = defineStore({
  id: "appStore",
  state: () => ({
    token: "",
    login: null,
    password: null,
    passwordExpirationDate: null,
    enteredPassword: null,
    data: null,
    clickedButtonsCount: 0,
    compareButtonClicked: false,
    tokenIsValid: false,
    cookieLoaded: false,
    dataLoaded: false,
    offerLoaded: false,
    dataToCompare: null,
    goAnotherStep: false,
    allowComponentsToLoad: false,
    scrollUp: false,
    selectedProduct: null,
    financingScroll: false,
    accessoriesScroll: false,
    specialsScroll: false,
    configurationScroll: false,
    sliderScroll: false,
    offersIndex: [],
    mainOffer: [],
    footerLogo: null, 
    logo: null,
    cscClientId: null,
    defaultOfferID: null,
    offers: null,
    firstLoginEvent: null,
    defaultLoginEvent: null,
    updateChangeOfferFromCompare: false,
    loginInProcess: false,
    disableAddMainOffer: false,
    loginError: false,
    passwordError: false,
    tooManyAttempts: false,
    filesButtonClicked: false,
    mainColor: null,
    loginImage: null,
    video: null,
    logoProgram: null,
    primaryFont: null,
    loginBackground: null,
    galleryImage: null,
    blockTracking: false,
    query: null,
    hideLoginButton: false,
    loginMethodMail: false,
    noOffersCommunicate: false,
  }),
  actions: {
    set(key, value) {
      if (key in this) {
        this[key] = value;
      }
    },
    resetScrollAction(newValue) {
      const scrollKeys = ['specialsScroll', 'sliderScroll', 'financingScroll', 'accessoriesScroll', 'configurationScroll'];
      scrollKeys.forEach(key => this.set(key, newValue));
    },
    updateIndex(index) {
      const indexInArray = this.offersIndex.indexOf(index);
      if (indexInArray !== -1) {
        this.offersIndex.splice(indexInArray, 1);
      } else {
        this.offersIndex.push(index);
      }
    },
    addMainOffer(offer){
      this.mainOffer.push(offer);
      if (this.mainOffer.length > 0) {
        this.mainOffer = []
        this.mainOffer.push(offer);
      }
    },
    resetIndex() {
      this.offersIndex = [];
    }
  },
  getters: {
    getMainOffer: state => state.mainOffer,
    getFooterLogo: state => state.footerLogo,
    getAssets: state => state.logo,
    getGalleryImage: state => state.galleryImage,
    getLoginPageImage: state => state.loginImage,
    getVideo: state => state.video,
    getBackground: state => state.loginBackground,
    getColor: state => state.mainColor,
    getLogoProgram: state => state.logoProgram,
  }
});
