<template>
  <div class="financing__container">
    <div class="financing__wrap">
      <svg
        class="slider__icon none financing__icon--prev"
        @click="slideList('left')"
      >
        <image href="../../assets/Icons/arrow_left.svg"></image>
      </svg>
      <ul class="financing__list" ref="financingListComponent">
        <li
          v-for="(detail, index) in financing"
          :key="index"
          class="financing__element"
          @click="checkProduct(detail.action)"
        >
          <div
            class="financing__block"
            :class="{
              'financing__block-activated':
                this.selectedProduct === detail.action,
            }"
          >
            <p
              class="financing__header"
              :class="{
                'financing__header-activated':
                  this.selectedProduct === detail.action,
              }"
            >
              {{ detail.header }}
            </p>
          </div>
          <div
            class="financing__inside"
            :class="{
              'financing__inside-activated':
                this.selectedProduct === detail.action,
            }"
          >
            <div class="financing__wrap">
              <svg class="financing__icon">
                <image class="financing__icon--image" :href="icon"></image>
              </svg>
              <p class="financing__text">
                {{ detail.text1 }}
              </p>
            </div>
            <div class="financing__wrap">
              <svg class="financing__icon">
                <image class="financing__icon--image" :href="icon"></image>
              </svg>
              <p class="financing__text">
                {{ detail.text2 }}
              </p>
            </div>
            <div class="financing__wrap">
              <svg class="financing__icon">
                <image class="financing__icon--image" :href="icon"></image>
              </svg>
              <p class="financing__text">
                {{ detail.text3 }}
              </p>
            </div>
          </div>
        </li>
      </ul>
      <svg
        class="slider__icon none financing__icon--next"
        @click="slideList('right')"
      >
        <image href="../../assets/Icons/arrow_right.svg"></image>
      </svg>
    </div>
  </div>
</template>

<script>
import { useStore } from "../../store";
import { isInViewport } from "../../plugins/helpers";
import httpActions from "../../plugins/httpActionService";

export default {
  computed: {
    mainOffer() {
      return useStore().getMainOffer;
    },
  },
  data() {
    return {
      currentPosition: 0,
      slideStepMax: 246,
      slideStepMin: -246,
      selectedProduct: null,
      financing: [
        {
          action: "rent",
          header: "Wynajem",
          text1: "Leasing z wyjątkowo niską ratą miesięczną",
          text2:
            "Stała stopa oprocentowania - niezmienna wysokość rat miesięcznych",
          text3:
            "Na koniec umowy - zwrot pojazdu do Dealera lub wykup za kwotę wartości Końcowej zapisanej w umowie",
        },
        {
          action: "leasing",
          header: "Leasing",
          text1:
            "Jeśli chcesz zatrzymać swojego nowego Mercedesa na dłużej, to jest idealny produkt dla Ciebie",
          text2:
            "Promocyjny leasing na okres od 24 do 60 miesięcy z niskim całkowitym kosztem procentowym umowy",
          text3:
            "Niezmienna wysokość rat miesięcznych w całym okresie umowy dzięki stałej stopie oprocentowania",
        },
        {
          action: "credit",
          header: "Kredyt / pożyczka",
          text1:
            "Dogodna pożyczka na promocyjnych warunkach na zakup pojazdu dla Twojej firmy",
          text2: "Korzystna forma zakupu ratalnego Twojego Mercedesa",
          text3:
            "Niezmienna wysokość rat miesięcznych w całym okresie umowy dzięki stałej stopie oprocentowania",
        },
      ],
      icon: require("../../assets/Icons/check-blue.png"),
      section: "n78fx3",
      isInView: false,
      isInViewFlag: false,
      allowToTrack: false,
      allowToTrackFlag: false,
    };
  },
  methods: {
    slideList(direction) {
      const list = this.$refs.financingListComponent;
      const step = direction === "left" ? this.slideStepMax : this.slideStepMin;

      if (
        (direction === "left" && this.currentPosition < this.slideStepMax) ||
        (direction === "right" && this.currentPosition > this.slideStepMin)
      ) {
        this.currentPosition += step;
        list.style.transition = "transform 0.5s";
        list.style.transform = `translateX(${this.currentPosition}px)`;
      }
    },

    checkProduct(product) {
      if (product === this.selectedProduct) {
        this.selectedProduct = null;
        useStore().set("selectedProduct", null);
      } else {
        this.selectedProduct = product;
        useStore().set("selectedProduct", product);
      }
    },

    checkViewport() {
      const element = this.$refs.financingListComponent;
      if (element) {
        this.isInView = isInViewport(element);
        if (this.isInView && !this.isInViewFlag) {
          this.isInViewFlag = true;
          this.allowToTrack = true;
          setTimeout(() => {
            if (this.allowToTrack && this.allowToTrackFlag) {
              const arr = []
              arr.push(useStore().mainOffer[0].id)
              httpActions.trackSection(
                this.section,
                arr
              );
            }
            this.allowToTrackFlag = false;
            this.isInViewFlag = false;
          }, 1500);
        }
        if (this.isInView === false) {
          this.allowToTrack = false;
        }
      }
    },
  },
  mounted() {
    this.checkViewport();
    window.addEventListener("scroll", this.checkViewport);
  },
  beforeUnmount() {
    window.removeEventListener("scroll", this.checkViewport);
  },
  watch: {
    isInView(newVal) {
      if (newVal) {
        this.allowToTrackFlag = true;
      }
    },
    mainOffer(newVal) {
      if (newVal) {
        this.allowToTrackFlag = true;
        this.checkViewport();
      }
    }
  }
};
</script>
