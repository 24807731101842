<template>
  <div class="heading__wrapper">
    <h2 class="heading">{{ HeadingText }}</h2>
    <svg class="heading__icon" @click="scrollUp">
      <image href="../../assets/Icons/group66.svg"></image>
    </svg>
  </div>
</template>

<script>
import { useStore } from '../../store';

export default {
  props: {
    HeadingText: String,
  },
  methods: {
    scrollUp() {
      useStore().set("scrollUp", true);
    }
  }
};
</script>

