<template>
  <table class="comparing__table" ref="comparingTable">
    <Carousel ref="equipmentCarousel" :mouse-drag="mouseDrag" :touch-drag="touchDrag">
    <Slide v-for="(row, rowIndex) in rows" :key="rowIndex" class="comparing__row">
      <td
        v-for="(cell, cellIndex) in row"
        :key="cell.id"
        class="comparing__data"
        :class="{ 'no-display': cellIndex >= visibleCells }"
      >
        <svg width="20" height="15" v-if="cell.value === 'true'">
          <image
            href="../../assets/Icons/check.svg"
            width="20"
            height="15"
          ></image>
        </svg>
        <span v-else>-</span>
      </td>
    </Slide>
  </Carousel>
  </table>
</template>

<script>
import "vue3-carousel/dist/carousel.css";
import { Carousel, Slide } from "vue3-carousel";

export default {
  components: {
    Carousel,
    Slide
  },
  props: {
    rows: Array,
    visibleCells: Number,
  },
  data() {
    return {
      rowsAmount: 0,
      rowsShown: 0,
      mouseDrag: false,
      touchDrag: false,
    };
  },
  emits: ["rowsAmount", "rowsAmountShown"],
  methods: {
    countRowsAmount() {
      const lastTable = this.$refs.comparingTable;
      if (lastTable) {
        const lastRow = lastTable.querySelector(".comparing__row:last-child");
        if (lastRow) {
          this.rowsAmount = lastRow.querySelectorAll(".comparing__data").length;
          this.$emit("rowsAmount", this.rowsAmount);
        } else {
          this.rowsAmount = 0;
          this.$emit("rowsAmount", this.rowsAmount);
        }
      }
    },

    countRowsShown() {
      const lastRow = document.querySelector(
        ".comparing__table .comparing__row:last-child"
      );
      if (lastRow) {
        const shownCells = lastRow.querySelectorAll(
          ".comparing__data:not(.no-display)"
        );
        this.rowsShown = shownCells.length - 1;
        this.$emit("rowsAmountShown", this.rowsShown);
      } else {
        this.rowsShown = 5;
        this.$emit("rowsAmountShown", this.rowsShown);
      }
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.countRowsAmount();
      this.countRowsShown();
    });
  },
};
</script>
