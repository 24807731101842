<template>
  <table class="comparing__table">
    <Carousel ref="infoCarousel" :mouse-drag="mouseDrag" :touch-drag="touchDrag">
    <Slide v-for="(info, index) in infos" :key="index" class="comparing__row">
      <td v-if="info.version" class="comparing__data">{{ info.version }}</td>
      <td v-else class="comparing__data">Wersja</td>
      <td class="comparing__data">{{ formatPrice(info.catalogPrice) }} zł brutto</td>
      <td class="comparing__data">
        {{ info.productionYear }}
      </td>
      <td class="comparing__data">{{ capitalizeFirstLetter(info.fuelType) }}</td>
      <td class="comparing__data">
        {{ capitalizeFirstLetter(info.gearboxType) }}
      </td>
      <td class="comparing__data">
        {{ info.engineCapacity }} cm³
      </td>
      <td class="comparing__data">{{ capitalizeFirstLetter(info.power) }}</td>
      <td class="comparing__data">{{ formatMileage(info.mileage) }} km</td>
    </Slide>
    </Carousel>
  </table>
</template>

<script>
import "vue3-carousel/dist/carousel.css";
import { Carousel, Slide } from "vue3-carousel";
import { capitalizeFirstLetter, formatMileage, formatPrice } from "../../plugins/helpers";

export default {
  props: {
    infos: Array,
  },
  components: {
    Carousel,
    Slide,
  },
  methods: {
    capitalizeFirstLetter,
    formatMileage,
    formatPrice
  },
  data() {
    return {
      mouseDrag: false,
      touchDrag: false,
    }
  }
};
</script>
