<template>
  <div
    class="slider__car--info"
    ref="sliderComponent"
    :class="{ slider__animation: animation }"
  >
    <div class="slider__car--name">
      <p
        v-if="mainOffer[0] && mainOffer[0].make && mainOffer[0].model"
        class="slider__car--text"
        :class="{
          'slider__car--text-medium':
            mainOffer[0].make.length + mainOffer[0].model.length > 15 &&
            mainOffer[0].make.length + mainOffer[0].model.length <= 35,
          'slider__car--text-small':
            mainOffer[0].make.length + mainOffer[0].model.length > 35,
        }"
      >
        {{ mainOffer[0].make }} {{ mainOffer[0].model }}
      </p>
      <p
        v-if="mainOffer[0] && mainOffer[0].version"
        class="slider__car--text"
        :class="{
          'slider__car--text-medium':
            mainOffer[0].version.length > 15 &&
            mainOffer[0].version.length <= 35,
          'slider__car--text-small': mainOffer[0].version.length > 35,
        }"
      >
        {{ mainOffer[0].version }}
      </p>
      <p
        v-if="
          !mainOffer[0] &&
          !mainOffer[0].make &&
          !mainOffer[0].model &&
          !mainOffer[0].version
        "
        class="slider__car--text"
      >
        {{ placeholderModel }}
      </p>
    </div>

    <SliderCarBlock></SliderCarBlock>
  </div>
  <SliderButton
    v-if="mainOffer[0] && mainOffer[0].videoUrl !== null"
    :switchView="switchView"
    @update:switchView="updateSwitchView"
    :isMain="switchView"
  ></SliderButton>
  <SliderPresentationMain
    v-if="switchView && mainOffer[0] && mainOffer[0].videoUrl !== null"
  ></SliderPresentationMain>
  <SliderPresentationCar v-else></SliderPresentationCar>
</template>

<script>
import SliderButton from "./SliderButton.vue";
import SliderPresentationMain from "./SliderPresentationMain.vue";
import SliderCarBlock from "./SliderCarBlock.vue";
import SliderPresentationCar from "./SliderPresentationCar.vue";
import { useStore } from "../../store";

export default {
  components: {
    SliderButton,
    SliderPresentationMain,
    SliderCarBlock,
    SliderPresentationCar,
  },
  data() {
    return {
      switchView: true,
      placeholderModel: "Przykładowa marka samochodu",
      animation: false,
    };
  },
  computed: {
    allowToScroll() {
      return useStore().scrollUp;
    },
    mainOffer() {
      return useStore().getMainOffer;
    },
  },
  methods: {
    updateSwitchView(newValue) {
      this.switchView = newValue;
    },
    scrollToComponent() {
      const element = this.$refs.sliderComponent;
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
      useStore().resetScrollAction(false);
    },
  },
  mounted() {
    if (useStore().sliderScroll) {
      this.scrollToComponent();
    }
    this.animation = true;
  },
  watch: {
    allowToScroll(newVal) {
      if (newVal) {
        this.scrollToComponent();
        useStore().set("scrollUp", false);
      }
    },
    mainOffer(newVal) {
      if (newVal) {
        this.animation = false;
        setTimeout(() => {
          this.animation = true;
        }, 100);
      }
    },
  },
};
</script>
