<template>
  <div class="slider__button--container" :class="{'slider__animation' : animation}">
    <button
      class="slider__button"
      :class="{ active: activeButton === 'wideo' }"
      @click="setActiveButton('wideo')"
    >
      {{ $t("videoPresentation") }}
    </button>
    <button
      class="slider__button"
      :class="{ active: activeButton === 'galeria' }"
      @click="setActiveButton('galeria')"
    >
      {{ $t("gallery") }}
    </button>
  </div>
</template>


<script>
import { useStore } from "../../store";

export default {
  props: {
    switchView: Boolean,
    isMain: Boolean,
  },
  computed: {
    mainOffer() {
      return useStore().getMainOffer;
    },
  },
  data() {
    return {
      activeButton: "wideo",
      isMainPage: true,
      animation: false,
    };
  },
  emits: ["update:switchView"],
  methods: {
    setActiveButton(buttonType) {
      this.activeButton = buttonType;
      if (buttonType === "wideo") {
        this.$emit("update:switchView", true);
      } else if (buttonType === "galeria") {
        this.$emit("update:switchView", false);
      }
    },
  },
  mounted() {
    this.animation = true;
  },
  watch: {
    mainOffer(newValue) {
      if (newValue) {
        this.animation = false;
        setTimeout(() => {
          this.animation = true;
        }, 100);
      }
    },
  },
};
</script>
