<template>
  <div class="side__container">
    <p class="side__text">{{ $t("contactWithConsultant") }}</p>
    <SideConsultant
      v-for="(specialist, index) in specialists.slice(0, 2)"
      :key="index"
      :name="specialist.name"
      :proffession="specialist.position"
      :picture="specialist.photo"
      :buttonText="specialist.phoneNumber"
      :id="specialist.id"
    ></SideConsultant>
  </div>
</template>

<script>
import SideConsultant from "./SideConsultant.vue";
import { useStore } from "../../store";

export default {
  data() {
    return {
      specialists: "",
    };
  },
  computed: {
    changeOffer() {
      return useStore().data;
    },
  },
  components: {
    SideConsultant,
  },
  methods: {
    getData() {
      let advisors;
      if (useStore().data) {
        advisors = useStore().data.advisors;
        this.specialists = advisors.sort((a, b) => {
          if (a.role === "main" && b.role !== "main") {
            return -1;
          }
          if (a.role !== "main" && b.role === "main") {
            return 1;
          }
          return 0;
        });
      }
    },
  },
  mounted() {
    this.getData();
  },
  watch: {
    changeOffer(newVal) {
      if (newVal) {
        this.getData();
      }
    },
  },
};
</script>
