<template>
  <div class="sidebar__block">
    <div v-if="picture" class="sidebar__picture">
      <img :src="picture" />
    </div>
    <div
      v-else
      class="sidebar__picture"
      :style="{ 'background-color': backgroundColor }"
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="17.5"
        height="19.5"
        viewBox="0 0 17.5 19.5"
      >
        <g id="user" transform="translate(-3.25 -2.25)">
          <path
            id="Path_496"
            data-name="Path 496"
            d="M12,11.75A4.75,4.75,0,1,0,7.25,7,4.754,4.754,0,0,0,12,11.75Zm0-8A3.25,3.25,0,1,1,8.75,7,3.256,3.256,0,0,1,12,3.75Z"
            fill="#fff"
          />
          <path
            id="Path_497"
            data-name="Path 497"
            d="M15,13.25H9A5.757,5.757,0,0,0,3.25,19,2.748,2.748,0,0,0,6,21.75H18A2.748,2.748,0,0,0,20.75,19,5.757,5.757,0,0,0,15,13.25Zm3,7H6A1.25,1.25,0,0,1,4.75,19,4.259,4.259,0,0,1,9,14.75h6A4.259,4.259,0,0,1,19.25,19,1.25,1.25,0,0,1,18,20.25Z"
            fill="#fff"
          />
        </g>
      </svg>
    </div>
    <div class="sidebar__consultant">
      <h4 class="sidebar__picture--header">{{ name }}</h4>
      <p class="sidebar__picture--description">{{ proffession }}</p>
      <a
        v-if="buttonText"
        :href="`tel:${callText}`"
        :class="{
          sidebar__button: returnColor === '',
          'sidebar__button--bordo': returnColor === '#801C62',
        }"
        @mouseover="changeButton"
        @mouseout="restoreButton"
        @click="sendNumber()"
      >
        {{ callText }}
      </a>
    </div>
  </div>
</template>

<script>
import httpActionService from "../../plugins/httpActionService";
import { useStore } from "../../store";

export default {
  props: {
    name: String,
    proffession: String,
    picture: String,
    buttonText: String,
    id: Number,
  },
  data() {
    return {
      callText: "Zadzwoń",
      backgroundColor: "#909090",
    };
  },
  computed: {
    returnColor() {
      return useStore().getColor;
    },
  },
  methods: {
    changeButton() {
      if (this.buttonText.includes(" ")) {
        const formattedNumber = this.buttonText.replace(/-/g, "");
        this.callText = formattedNumber;
      } else {
        const formattedNumber = this.buttonText.replace(/.{3}(?!$)/g, "$& ");
        this.callText = formattedNumber;
      }
    },
    restoreButton() {
      this.callText = "Zadzwoń";
    },
    sendNumber() {
      httpActionService.advisorClicked("saqwt2", this.id);
    },
  },
};
</script>
