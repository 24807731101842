<template>
  <footer class="footer">
    <div class="footer__container">
      <!-- <img
        v-if="footerLogo"
        class="footer__logo"
        :src="require(`../../assets/Images/${footerLogo}`)"
        alt="duda-cars logo"
      />
      <img
        v-if="false"
        class="footer__logo"
        src="../../assets/Images/duda-footer.webp"
        alt="duda-cars logo"
      /> -->
      <!-- <p class="footer__logo">Tutaj nazwa salonu</p> -->
      <div class="footer__wrapper">
        <div class="footer__block">
          <p class="footer__header">{{ name }}</p>
          <p v-if="address && address.street" class="footer__text">
            {{ address.street }}
          </p>
          <p
            v-if="address && address.city && address.postalCode"
            class="footer__text"
          >
            {{ address.postalCode }} {{ address.city }}
          </p>
          <p v-if="address && address.phoneNumber" class="footer__text">
            {{ address.phoneNumber }}
          </p>
          <p v-if="mail" class="footer__text">{{ mail }}</p>
          <p v-if="url" class="footer__text">
            <a class="footer__link">{{ url }}</a>
          </p>
        </div>
        <div class="footer__block">
          <p class="footer__header">Godziny otwarcia Salonu</p>
          <div
            v-if="openingHours && openingHours['PON-PT']"
            class="footer__text--block"
          >
            <p class="footer__text">PN-PT</p>
            <p class="footer__text">{{ openingHours["PON-PT"] }}</p>
          </div>
          <div
            v-if="openingHours && openingHours.SOB"
            class="footer__text--block"
          >
            <p class="footer__text">SO</p>
            <p class="footer__text">{{ openingHours.SOB }}</p>
          </div>
        </div>
      </div>
    </div>
    <div v-if="openingHours" class="footer__container">
      <l-map
        :zoom="15"
        :center="[coordinates.x, coordinates.y]"
      >
        <l-tile-layer
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        ></l-tile-layer>
        <l-marker :lat-lng="[coordinates.x, coordinates.y]" :icon="customIcon">
          <l-tooltip permanent>{{ name }}</l-tooltip>
        </l-marker>
      </l-map>
    </div>
  </footer>
</template>

<script>
import "leaflet/dist/leaflet.css";
import L from "leaflet";
import { LMap, LTileLayer, LMarker, LTooltip } from "@vue-leaflet/vue-leaflet";
import { useStore } from "../../store";
import customIconUrl from "../../assets/Icons/marker.svg";

const customIcon = L.icon({
  iconUrl: customIconUrl,
  iconSize: [32, 37], 
  iconAnchor: [16, 37], 
  popupAnchor: [0, -37],
});

export default {
  components: {
    LMap,
    LTileLayer,
    LMarker,
    LTooltip,
  },
  data() {
    return {
      address: null,
      name: null,
      openingHours: null,
      mail: null,
      url: null,
      coordinates: null,
      customIcon,
    };
  },
  computed: {
    footerLogo() {
      return useStore().getFooterLogo;
    },
    mapCoordinates() {
      if (useStore().data) {
        return JSON.parse(useStore().data.showroom.coordinates);
      }
    },
    returnCscClientID() {
      return useStore().cscClientId;
    },
  },
  methods: {
    getData() {
      if (useStore().data) {
        this.name = useStore().data.showroom.name;
        this.address = JSON.parse(useStore().data.showroom.address);
        this.openingHours = JSON.parse(useStore().data.showroom.openingHours);
        this.coordinates = JSON.parse(useStore().data.showroom.coordinates);
      }
    },
  },
  mounted() {
    this.getData();
  },
};
</script>
