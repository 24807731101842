import { createApp } from "vue";
import App from "./App.vue";
import { createI18n } from "vue-i18n";
import { createRouter, createWebHistory } from "vue-router";
import axios from "axios";
import { createPinia } from "pinia";
import dotenv from "dotenv"

dotenv.config();

import LoginPage from "./components/Pages/LoginPage.vue";
import OfferPage from "./components/Pages/OfferPage.vue";
import ComparePage from "./components/Pages/ComparePage.vue";

export const router = createRouter({
  history: createWebHistory(),
  routes: [
    { path: "/login", component: LoginPage },
    { path: "/main", component: OfferPage },
    { path: "/compare", component: ComparePage },
    { path: "/", component: LoginPage },
  ],
});

const i18n = createI18n({
  locale: "pl",
  fallbackLocale: "pl",
  messages: {
    pl: require("./locales/pl.json"),
  },
});

const app = createApp(App);
const pinia = createPinia();
app.config.globalProperties.axios = axios;
app.use(i18n);
app.use(pinia);
app.use(router);

app.mount("#app");

export default app;
