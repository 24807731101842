<template>
  <p class="sidebar__header">{{ $t("yourOffers") }}</p>
  <Multiselect
    v-if="options.length > 0"
    class="sidebar__multiselect"
    v-model="value"
    placeholder="Wybierz"
    label="showroomName"
    :options="options"
    @select="changeOffer(value)"
  >
    <template v-slot:singlelabel="{ value }">
      <div class="multiselect-single-label">
        {{ value.showroomName }}
      </div>
    </template>

    <template v-slot:option="{ option }">
      <p>{{ option.showroomName }}</p>
      <p>{{ option.mainAdvisorName }}</p>
    </template>
  </Multiselect>
  <div class="sidebar__block">
    <SideOfferCar :clearData="clearData"></SideOfferCar>
  </div>
</template>

<script>
import SideOfferCar from "./SideOfferCar.vue";
import Multiselect from "@vueform/multiselect";
import { useStore } from "../../store";
import httpActions from "../../plugins/httpActionService";
import dataToCompare from "../../plugins/dataToCompare";

export default {
  props: {
    compareIsNotActive: Boolean,
  },
  data() {
    return {
      comparingClosed: false,
      value: [],
      options: [],
      index: 1,
      clearData: false,
    };
  },
  components: {
    SideOfferCar,
    Multiselect,
  },
  computed: {
    actualOfferId() {
      return useStore().defaultOfferID;
    },
  },
  methods: {
    getOffers() {
      if (useStore().offers) {
        const offerTable = useStore().offers;
        let filteredTable;
        if (localStorage.getItem("lastSeenOffer")) {
          filteredTable = offerTable.filter(
            (offer) => offer.offerId != localStorage.getItem("lastSeenOffer")
          );
        } else {
          filteredTable = offerTable.filter(
            (offer) => offer.offerId != useStore().defaultOfferID
          );
        }

        filteredTable.forEach((offer) => {
          offer.value = this.index++;
          this.options.push(offer);
        });
      }
    },
    changeOffer(value) {
      if (this.$route.path === "/compare") {
        useStore().set("updateChangeOfferFromCompare", true);
        this.$router.push("/main");
      }
      this.clearData = true;
      dataToCompare.data = [];
      localStorage.removeItem("dataToCompare");
      useStore().set("clickedButtonsCount", 0);
      useStore().set("offersIndex", []);
      useStore().set(
        "defaultOfferID",
        this.options.filter((option) => option.value === value)[0].offerId
      );
      httpActions.trackChangeOffer(useStore().defaultOfferID);
      if (this.$route.path !== "/compare") {
        httpActions.loadComponents();
      }
      this.$nextTick(() => {
        this.clearData = false;
      });
      localStorage.setItem("lastSeenOffer", useStore().defaultOfferID);
    },
  },
  mounted() {
    this.getOffers();
  },
  watch: {
    actualOfferId(newVal) {
      if (newVal) {
        this.options = [];
        this.getOffers();
      }
    },
  },
};
</script>

<style src="@vueform/multiselect/themes/default.css"></style>
