<template>
  <section
    class="comparing"
    @mousedown="startDragging"
    @mouseleave="stopDragging"
    @mouseup="stopDragging"
    @mousemove="dragPage"
    ref="comparingSection"
  >
    <h2 class="comparing__header">{{ $t("compareOffers") }}</h2>
    <div class="comparing__list--container">
      <div class="comparing__list--wrapper-up">
        <svg
          class="slider__icon comparing__icon--prev is-hidden"
          @click="slideComparingSlides('left')"
        >
          <image href="../../assets/Icons/arrow_left.svg"></image>
        </svg>
        <Carousel
          ref="imageCarousel"
          class="comparing__list"
          :mouse-drag="mouseDrag"
          :touch-drag="touchDrag"
        >
          <Slide
            class="comparing__element"
            v-for="image in images"
            :key="image.id"
          >
            <img
              class="comparing__image"
              :src="image.imageUrl"
              :alt="image.alt"
            />
          </Slide>
        </Carousel>
        <svg
          class="slider__icon comparing__icon--next is-hidden"
          @click="slideComparingSlides('right')"
        >
          <image href="../../assets/Icons/arrow_right.svg"></image>
        </svg>
      </div>
    </div>
    <div
      class="comparing__list--container"
      ref="gradient"
      :class="{ 'comparing__list--gradient': gradientAllow }"
    >
      <div class="comparing__list--wrapper-down">
        <Carousel
          ref="modelCarousel"
          class="comparing__list--below"
          :mouse-drag="mouseDrag"
          :touch-drag="touchDrag"
        >
          <Slide
            class="comparing__element--below"
            v-for="(model, index) in infos"
            :key="index"
          >
            <p class="comparing__model">
              {{ model.make + " " + model.model }}
            </p>
            <p class="comparing__price">
              {{ formatPrice(model.forYouPrice) }}
              {{ $t("currency") }} brutto
            </p>
          </Slide>
        </Carousel>
      </div>
    </div>
    <div class="comparing__main">
      <div class="comparing__side">
        <ul class="comparing__side--list">
          <li
            v-for="description in descriptions"
            :key="description"
            class="comparing__side--element"
          >
            {{ description.description }}
          </li>
        </ul>
        <p class="comparing__side--header">{{ $t("equipmentList") }}</p>
        <ComparingListDetails
          :items="items"
          :visibleRows="shownRowCount"
        ></ComparingListDetails>
        <div class="left--blockage"></div>
        <div class="blur-left"></div>
      </div>
      <div class="comparing__main--right">
        <div class="comparing__list--block-up" ref="blockUpComponent">
          <ComparingTableUp
            ref="infoCarousel"
            :infos="infos"
          ></ComparingTableUp>
        </div>
        <div class="comparing__list--block-down" ref="blockDownComponent">
          <ComparingTableDown
            :rows="rows"
            :visibleCells="shownRowCount"
            @rowsAmountShown="handleRowsAmountShown"
            ref="tableDownComponent"
          ></ComparingTableDown>
        </div>
      </div>
    </div>
    <ShowMore
      :allRows="rowsAmount"
      :shownRows="shownRowCount"
      @showMoreClicked="showMoreClicked"
      @showAllClicked="showAllClicked"
    ></ShowMore>
    <div class="blur-right"></div>
  </section>
</template>

<script>
import ShowMore from "../YourFiles/ShowMore.vue";
import ComparingTableUp from "./ComparingTableUp.vue";
import ComparingTableDown from "./ComparingTableDown.vue";
import ComparingListDetails from "./ComparingListDetails.vue";
import { formatPrice, isOnTop } from "../../plugins/helpers";
import "vue3-carousel/dist/carousel.css";
import { Carousel, Slide } from "vue3-carousel";
import httpActions from "../../plugins/httpActionService";
import { useStore } from "vuex";

export default {
  components: {
    ShowMore,
    ComparingTableUp,
    ComparingTableDown,
    ComparingListDetails,
    Carousel,
    Slide,
  },
  data() {
    return {
      images: [],
      items: [],
      descriptions: [
        { description: "Wersja" },
        { description: "Cena katalogowa" },
        { description: "Rok produkcji" },
        { description: "Silnik" },
        { description: "Skrzynia biegów" },
        { description: "Pojemność silnika" },
        { description: "Moc silnika" },
        { description: "Przebieg" },
      ],
      infos: [],
      rows: [],
      shownRowCount: 0,
      rowsAmount: Infinity,
      isDragging: false,
      mouseDrag: false,
      touchDrag: false,
      startX: 0,
      itemsBefore: [],
      section: "xllfy4",
      gradientAllow: false,
    };
  },
  methods: {
    slideComparingSlides(direction) {
      const infoCarousel = this.$refs.infoCarousel.$refs.infoCarousel;
      const equipmentCarousel =
        this.$refs.tableDownComponent.$refs.equipmentCarousel;
      const allSlides = this.images.length;
      const difference = allSlides - infoCarousel.data.currentSlide.value;
      if (direction === "left") {
        this.$refs.modelCarousel.prev();
        this.$refs.imageCarousel.prev();
        infoCarousel.prev();
        equipmentCarousel.prev();
      } else if (direction === "right" && difference > 2) {
        this.$refs.modelCarousel.next();
        this.$refs.imageCarousel.next();
        infoCarousel.next();
        equipmentCarousel.next();
      }
    },

    handleRowsAmount() {
      this.$nextTick(() => {
        this.rowsAmount = this.items.length;
      });
    },

    handleRowsAmountShown(newRowsAmountShown) {
      this.$nextTick(() => {
        this.shownRowCount = newRowsAmountShown;
      });
    },

    showMoreClicked() {
      this.shownRowCount += 5;
      if (this.shownRowCount > this.rowsAmount) {
        this.shownRowCount = this.rowsAmount;
      }
    },

    showAllClicked() {
      this.shownRowCount = this.rowsAmount;
    },

    startDragging(event) {
      if (window.innerWidth < 980) {
        this.isDragging = true;
        this.startX = event.pageX - this.$el.offsetLeft;
        this.scrollLeft = this.$el.scrollLeft;
      }
    },

    stopDragging() {
      this.isDragging = false;
    },

    dragPage(event) {
      if (!this.isDragging) return;
      event.preventDefault();
      const x = event.clientX - this.$el.offsetLeft;
      const walk = x - this.startX;
      this.$el.scrollLeft = this.scrollLeft - walk;
    },

    formatPrice,

    parseData() {
      this.$nextTick(() => {
        const storedData = JSON.parse(localStorage.getItem("dataToCompare"));
        if (storedData) {
          this.infos = storedData;
          this.images = storedData.map((index) => ({
            id: index + 1,
            imageUrl: index.photos[0],
            alt: "zdjecie",
          }));

          for (let i = 0; i < storedData.length; i++) {
            const parsedJsonArray = JSON.parse(storedData[i].equipment);
            const parsedJsonAgain = JSON.parse(parsedJsonArray);
            this.itemsBefore.push(parsedJsonAgain);
          }

          const uniqueIdsAndNames = new Set();

          this.itemsBefore.forEach((arr) => {
            arr.forEach((item) => {
              const { id, name } = item;
              uniqueIdsAndNames.add(`${id}-${name}`);
            });
          });

          this.items = [...uniqueIdsAndNames]
            .sort((a, b) => {
              const nameA = a.split("-")[1];
              const nameB = b.split("-")[1];
              return nameA.localeCompare(nameB);
            })
            .map((name) => {
              const [id, item] = name.split("-");
              return { id, item };
            });

          this.rows = storedData.map((vehicle) => {
            return this.items.map((item) => {
              const parsedJsonArray = JSON.parse(vehicle.equipment);
              const parsedJsonAgain = JSON.parse(parsedJsonArray);
              const hasEquipment = parsedJsonAgain.some((equipment) => {
                return equipment.name === item.item;
              });
              return {
                id: item.id,
                value: hasEquipment ? "true" : "false",
              };
            });
          });
        }
      });
    },

    trackCompare() {
      this.$nextTick(() => {
        const data = JSON.parse(localStorage.getItem("dataToCompare"));
        const arr = [];
        if (data) {
          data.forEach((item) => {
            arr.push(item.id);
          });
          httpActions.trackSection(this.section, arr);
        }
      });
    },

    dataCheck() {
      if (
        localStorage.getItem("dataToCompare") === null ||
        JSON.parse(localStorage.getItem("dataToCompare")).length < 2
      ) {
        this.$router.push("/main");
      }
    },
    watchScroll() {
      const gradient = this.$refs.gradient;
      if (isOnTop(gradient)) {
        this.gradientAllow = true;
      } else {
        this.gradientAllow = false;
      }
    },
  },

  mounted() {
    this.dataCheck();
    this.parseData();
    this.handleRowsAmount();
    this.$refs.comparingSection.scrollIntoView({ behavior: "smooth" });
    this.trackCompare();
    window.addEventListener("scroll", this.watchScroll);
  },
  beforeUnmount() {
    window.removeEventListener("scroll", this.watchScroll);
  },
};
</script>
