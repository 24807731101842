<template>
  <div class="offer__container--block">
    <button
      :class="{
        'button__is-clicked': button.main && returnColor === '',
        offer__button: returnColor === '',
        'button__is-clicked--bordo': button.main && returnColor === '#801C62',
        'offer__button--bordo': returnColor === '#801C62',
      }"
      v-for="(button, index) in buttons"
      :key="index"
      ref="buttonComponent"
      @click="changeMainOffer(button)"
    >
      <div
        class="offer__button--check"
        :class="{ 'box__is-clicked': button.isShown }"
        @click.stop="matchButton(button, index)"
      >
        <svg
          class="offer__check--icon"
          :class="{ 'is-hidden-icon': !button.isShown }"
          width="18"
          height="14"
        >
          <image
            href="../../assets/Icons/check.svg"
            width="18"
            height="14"
          ></image>
        </svg>
      </div>
      <div class="offer__block">
        <p class="offer__button--description">
          {{ button.make }} {{ button.model }}
        </p>
      </div>
      <svg class="offer__icon">
        <image
          class="offer__icon--image"
          href="../../assets/Icons/arrow_left_test.png"
        ></image>
      </svg>
    </button>
  </div>
</template>

<script>
import dataToCompare from "../../plugins/dataToCompare";
import { useStore } from "../../store.js";

export default {
  props: {
    clearData: Boolean,
  },
  computed: {
    mainOffer() {
      return useStore().getMainOffer;
    },
    dataChange() {
      return useStore().data;
    },
    returnColor() {
      return useStore().getColor;
    },
  },
  data() {
    return {
      isAnyButtonClicked: false,
      buttons: [],
      clickedButtonsCount: 0,
    };
  },
  methods: {
    matchButton(button, index) {
      if (localStorage.getItem("dataToCompare")) {
        dataToCompare.data = JSON.parse(localStorage.getItem("dataToCompare"));
      } else {
        dataToCompare.data = [];
      }

      button.isShown = !button.isShown;
      useStore().updateIndex(index);

      const localStorageData = JSON.parse(
        localStorage.getItem("dataToCompare")
      );

      if (button.isShown) {
        dataToCompare.data.push(button);
        localStorage.setItem(
          "dataToCompare",
          JSON.stringify(dataToCompare.data)
        );
        this.clickedButtonsCount++;
      } else {
        const foundIndex = localStorageData.findIndex(
          (item) => item.make === button.make && item.model === button.model && item.id === button.id
        );
        if (foundIndex !== -1) {
          localStorageData.splice(foundIndex, 1);
          localStorage.setItem(
            "dataToCompare",
            JSON.stringify(localStorageData)
          );
        }
        this.clickedButtonsCount--;
      }
      this.isAnyButtonClicked = this.buttons.some((button) => button.isShown);
      useStore().set("compareButtonClicked", this.isAnyButtonClicked);
      useStore().set("clickedButtonsCount", this.clickedButtonsCount);
    },

    getData() {
      if (useStore().data) {
        this.buttons = useStore().data.vehicles;
        const localStorageData = JSON.parse(
          localStorage.getItem("dataToCompare")
        );
        if (localStorageData) {
          this.buttons.forEach((button) => {
            const found = localStorageData.find(
              (item) => item.make === button.make && item.model === button.model && item.id === button.id
            );
            if (found) {
              button.isShown = true;
              this.clickedButtonsCount++;
            }
          });
          this.isAnyButtonClicked = this.clickedButtonsCount > 0;
        } else {
          this.buttons = useStore().data.vehicles;
        }
      }
    },
    changeMainOffer(button) {
      useStore().addMainOffer(button);
      useStore().set("filesButtonClicked", false);
      if (this.$route.path === "/compare") {
        this.$router.push("/main");
      }
    },
    checkActiveOffer() {
      if (useStore().mainOffer[0]) {
        const mainOffer = useStore().mainOffer[0];
        this.buttons.forEach((button) => {
          if (
            button.make === mainOffer.make &&
            button.model === mainOffer.model &&
            button.id === mainOffer.id
          ) {
            button.main = true;
          }
        });
      }
    },
  },
  mounted() {
    this.getData();
    this.checkActiveOffer();
    if (
      this.$route.path === "/compare" &&
      !useStore().updateChangeOfferFromCompare
    ) {
      useStore().set("compareButtonClicked", false);
      useStore().set("clickedButtonsCount", 0);
      this.clickedButtonsCount = 0;
      this.buttons.forEach((button) => {
        button.isShown = false;
      });
      setTimeout(() => {
        useStore().set("disableAddMainOffer", true);
      }, 1000);
    }
  },
  watch: {
    mainOffer(newVal) {
      if (newVal) {
        this.buttons.forEach((button) => {
          button.main = false;
        });
        this.checkActiveOffer();
      }
    },
    dataChange(newVal) {
      if (newVal) {
        this.getData();
      }
    },
    clearData(newVal) {
      if (newVal) {
        useStore().set("compareButtonClicked", false);
        useStore().set("clickedButtonsCount", 0);
        this.clickedButtonsCount = 0;
        this.buttons.forEach((button) => {
          button.isShown = false;
        });
      }
    },
  },
};
</script>
