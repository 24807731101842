<template>
  <HeadingBlack
    :HeadingTextBlack="$t('equipmentList')"
    v-if="equipmentData.length > 0"
  ></HeadingBlack>
  <ConfigurationTableMobile
    v-if="screen && equipmentData.length > 0"
  ></ConfigurationTableMobile>
  <ConfigurationTable
    v-if="!screen && equipmentData.length > 0"
  ></ConfigurationTable>
  <p
    class="configuration__description"
    v-if="equipmentData.length < 1"
    v-html="changeText"
  ></p>
  <div class="sliderCarBlock__container">
    <SliderCarBlockConfiguration></SliderCarBlockConfiguration>
  </div>
</template>

<script>
import HeadingBlack from "../Header/HeadingBlack.vue";
import ConfigurationTable from "../Configuration/ConfigurationTable.vue";
import SliderCarBlockConfiguration from "../Slider/SliderCarBlockConfiguration.vue";
import ShowMore from "../YourFiles/ShowMore.vue";
import ConfigurationTableMobile from "./ConfigurationTableMobile.vue";
import { useStore } from "../../store";

export default {
  components: {
    HeadingBlack,
    ConfigurationTable,
    SliderCarBlockConfiguration,
    ShowMore,
    ConfigurationTableMobile,
  },
  props: {
    screen: Boolean,
  },
  computed: {
    mainOffer() {
      return useStore().getMainOffer;
    },
    changeText() {
      if (this.description) {
        const changedHTML = this.description.replace(/\n/g, "<br>");
        return changedHTML;
      }
    },
  },
  data() {
    return {
      equipmentData: "",
      description: "",
    };
  },
  methods: {
    getData() {
      if (useStore().mainOffer[0]) {
        const data = JSON.parse(useStore().mainOffer[0].equipment);
        this.description = useStore().mainOffer[0].description;
        this.equipmentData = JSON.parse(data);
      }
    },
  },
  mounted() {
    this.getData();
  },
  watch: {
    mainOffer(newValue) {
      if (newValue) {
        this.getData();
      }
    },
  },
};
</script>
