<template>
  <section
    v-if="getFiles && getFiles.length > 0"
    class="yourfiles yourfiles__animation"
    :style="handleStyles"
  >
    <HeadingGray :HeadingText="$t('yourFilesHeader')"></HeadingGray>
    <HeadingBlack :HeadingTextBlack="$t('yourFilesText')"></HeadingBlack>
    <YourFilesMobile v-if="screen" :allFiles="allFiles"></YourFilesMobile>
    <YourFilesTable v-else :allFiles="allFiles"></YourFilesTable>
  </section>
</template>

<script>
import HeadingGray from "../Header/HeadingGray.vue";
import HeadingBlack from "../Header/HeadingBlack.vue";
import YourFilesTable from "../YourFiles/YourFilesTable.vue";
import YourFilesMobile from "./YourFilesMobile.vue";
import { useStore } from "../../store";

export default {
  components: {
    HeadingBlack,
    HeadingGray,
    YourFilesTable,
    YourFilesMobile,
  },
  props: {
    screen: Boolean,
    allFiles: Boolean,
  },
  computed: {
    mainOffer() {
      return useStore().getMainOffer;
    },
    handleStyles() {
      if (this.allFiles) {
        return {
          "padding-top": "70px",
        };
      }
    },
    getFiles() {
      if (useStore().data) {
        if (this.allFiles) {
          return useStore().data.files;
        } else {
          return useStore().data.files.filter(
            (file) => file.vehicle_id === this.mainOffer[0].id
          );
        }
      }
    },
  },
  methods: {
    dataCheck() {
      if (this.allFiles && useStore().data) {
        if (useStore().data.files.length === 0) {
          this.$router.push("/main");
        }
      }
    },
  },
  mounted() {
    this.dataCheck();
  },
};
</script>
