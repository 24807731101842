<template>
  <header ref="headerComponent">
    <a href="/main" class="header__container" ref="headerComponent">
      <img v-if="returnLogo" class="header__logo--one" :src="require(`../../assets/Images/${returnLogo}`)" />
      <img v-if="false" class="header__logo--one" src="../../assets/Images/logo-dealer-demo.webp" />
      <img v-if="false" class="header__logo--one" src="../../assets/Images/ics.webp" />
      <img v-if="false" class="header__logo--one" src="../../assets/Images/nobile.webp" />
      <img v-if="returnLogoProgram === 'program-logo-demo.webp'" class="header__logo--two" :src="require(`../../assets/Images/${returnLogoProgram}`)" />
      <img v-if="false" class="header__logo--two" src="../../assets/Images/program-logo-demo.webp" />
      <img v-if="returnLogoProgram === 'nobile-program.webp'" class="header__logo--two" :src="require(`../../assets/Images/${returnLogoProgram}`)" />
      <img v-if="false" class="header__logo--two" src="../../assets/Images/nobile-program.webp" />
      <img v-if="returnLogoProgram === ''" class="header__logo--two" src="../../assets/Images/benz_logo.webp" />
    </a>
  </header>
</template>

<script>
import { useStore } from '../../store';

export default {
  computed: {
    returnLogo() {
      return useStore().getAssets;
    },
    returnLogoProgram() {
      return useStore().getLogoProgram;
    }
  },
}
</script>