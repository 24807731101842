<template>
  <div v-if="allowToLoad">
    <div v-if="showPreview" class="main__preview">Podgląd aplikacji</div>
    <div class="main">
      <div class="sidebar__wrapper" :class="{ 'blur-isOpen': this.menuIsOpen }">
        <sideBar-mobile
          v-if="isScreenBelow980px"
          @menuIsOpen="allowToBlur"
        ></sideBar-mobile>
        <sideBar v-else></sideBar>
      </div>
      <Header></Header>
      <comparing></comparing>
      <Footer></Footer>
    </div>
  </div>
  <div v-else class="loader__container">
    <pulseLoader :size="loaderSize" :color="loaderColor"></pulseLoader>
  </div>
</template>

<script>
import Footer from "../Footer/Footer.vue";
import SideBarMobile from "../SideBar/SideBarMobile.vue";
import SideBar from "../SideBar/SideBar.vue";
import Header from "../Header/Header.vue";
import Comparing from "../Comparing/Comparing.vue";
import { useStore } from "../../store";
import httpActions from "../../plugins/httpActionService";
import PulseLoader from "vue-spinner/src/PulseLoader.vue";
import dataToCompare from "../../plugins/dataToCompare";

export default {
  components: {
    Footer,
    SideBarMobile,
    SideBar,
    Header,
    Comparing,
    PulseLoader,
  },
  computed: {
    dataIsLoaded() {
      return useStore().dataLoaded;
    },
    showPreview() {
      if (useStore().query) {
        return true;
      } else {
        return false;
      }
    },
  },
  data() {
    return {
      isScreenBelow980px: window.innerWidth < 980,
      loaderSize: "60px",
      loaderColor: "black",
      menuIsOpen: false,
      section: "xllfy4",
      allowToLoad: false,
    };
  },
  methods: {
    checkScreenWidth() {
      this.isScreenBelow980px = window.innerWidth < 980;
    },

    allowToBlur(newValue) {
      this.menuIsOpen = newValue;
    },
  },
  mounted() {
    httpActions.getAssets();
    httpActions.tokenActive("/compare", "/login", useStore().query);
    window.addEventListener("resize", this.checkScreenWidth);
    setTimeout(() => {
      dataToCompare.data = [];
      localStorage.removeItem("dataToCompare");
    }, 4000);
  },
  beforeUnmount() {
    window.removeEventListener("resize", this.checkScreenWidth);
    useStore().set("clickedButtonsCount", 0);
    useStore().set("allowComponentsToLoad", false);
    useStore().set("offerLoaded", false);
    useStore().set("dataLoaded", false);
    dataToCompare.data = [];
    localStorage.removeItem("dataToCompare");
  },
  watch: {
    dataIsLoaded(newVal) {
      if (newVal) {
        this.allowToLoad = true;
      }
    },
  },
};
</script>
