<template>
  <section class="accesorries" ref="accessoriesComponent">
    <HeadingGray :HeadingText="$t('accessorriesHeader')"></HeadingGray>
    <HeadingBlack :HeadingTextBlack="$t('accessorriesTextBlack')"></HeadingBlack>
    <AccesorriesList></AccesorriesList>
  </section>
</template>

<script>
import HeadingGray from "../Header/HeadingGray.vue";
import HeadingBlack from "../Header/HeadingBlack.vue";
import AccesorriesList from "./AccesorriesList.vue";
import { useStore } from "../../store";

export default {
  components: {
    HeadingGray,
    HeadingBlack,
    AccesorriesList,
  },
  mounted() {
    if (useStore().accessoriesScroll) {
      this.$refs.accessoriesComponent.scrollIntoView({ behavior: "smooth" });
      useStore().resetScrollAction(false);
    }
  },
};
</script>
