<template>
  <h3 class="headingBlack">{{ HeadingTextBlack }}</h3>
</template>

<script>
export default {
  props: {
    HeadingTextBlack: String,
  },
};
</script>