<template>
  <ul class="specials__list" ref="trackComponent">
    <li v-for="detail in details" :key="detail" class="specials__element">
      <img
        :src="detail.url"
      />
      <p class="specials__description">{{ detail.label }}</p>
    </li>
  </ul>
</template>

<script>
import { useStore } from '../../store';
import { isInViewport } from "../../plugins/helpers";
import httpActions from "../../plugins/httpActionService";

export default {
  data() {
    return {
      details: [
        { url: require("../../assets/Images/paliwo@2x.webp"),  label: "Karta paliwowa o wartości 6 000 zł"},
        { url: require("../../assets/Images/drugi@2x.webp"),  label: "Ubezpieczenie AC/OC na rok"},
        { url: require("../../assets/Images/trzeci@2x.webp"),  label: "Oprawa Audio-wizualna przy odbiorze samochodu"},
      ],
      section: "h42i6u",
      isInView: false,
      isInViewFlag: false,
      allowToTrack: false,
      allowToTrackFlag: false,
    }
  },
  computed: {
    mainOffer() {
      return useStore().getMainOffer;
    }
  },
  methods: {
    checkViewport() {
      const element = this.$refs.trackComponent;
      if (element) {
        this.isInView = isInViewport(element);
        if (this.isInView && !this.isInViewFlag) {
          this.isInViewFlag = true;
          this.allowToTrack = true;
          setTimeout(() => {
            if (this.allowToTrack && this.allowToTrackFlag) {
              const arr = []
              arr.push(useStore().mainOffer[0].id)
              httpActions.trackSection(
                this.section,
                arr
              );
            }
            this.allowToTrackFlag = false;
            this.isInViewFlag = false;
          }, 1500);
        }
        if (this.isInView === false) {
          this.allowToTrack = false;
        }
      }
    },
  },
  mounted() {
    this.checkViewport();
    window.addEventListener("scroll", this.checkViewport);
  },
  beforeUnmount() {
    window.removeEventListener("scroll", this.checkViewport);
  },
  watch: {
    isInView(newVal) {
      if (newVal) {
        this.allowToTrackFlag = true;
      }
    },
    mainOffer(newVal) {
      if (newVal) {
        this.allowToTrackFlag = true;
        this.checkViewport();
      }
    }
  }
}
</script>