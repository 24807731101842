<template>
  <MainApp></MainApp>
</template>

<script>
import MainApp from "./components/MainApp.vue";

export default {
  components: {
    MainApp,
  },
};
</script>
