<template>
  <section class="slider__main" :class="{'slider__animation' : animation}">
    <div class="slider__wrapper" ref="trackComponent">
      <svg class="slider__icon slider__prev--icon" @click="slideSlides('left')">
        <image href="../../assets/Icons/arrow_left.svg"></image>
      </svg>
      <Carousel
        class="slider__list--container"
        ref="myCarousel"
        :mouse-drag="mouseDrag"
        :touch-drag="touchDrag"
      >
        <Slide class="slider__element" v-for="slide in slides" :key="slide">
          <div class="slider__slide-content">
            <video controls>
              <source :src="slide.src" />
            </video>
          </div>
        </Slide>
      </Carousel>
      <svg class="slider__icon slider__next--icon" @click="slideSlides('right')">
        <image href="../../assets/Icons/arrow_right.svg"></image>
      </svg>
    </div>
    <p class="slider__description">{{ descShortage(mainOffer[0].description) }}</p>
    <p class="slider__info" ref="sliderInfoComponent"></p>
  </section>
</template>

<script>
import { useStore } from "../../store";
import "vue3-carousel/dist/carousel.css";
import { Carousel, Slide } from "vue3-carousel";
import {descShortage} from '../../plugins/helpers'
import { isInViewport } from "../../plugins/helpers";
import httpActions from "../../plugins/httpActionService";

export default {
  components: {
    Carousel,
    Slide,
  },
  data() {
    return {
      currentSlide: 1,
      mouseDrag: false,
      touchDrag: false,
      description: null,
      slides: [
        {
          src: require("../../assets/Images/intro-demo.mp4"),
        },
        {
          src: require("../../assets/Images/intro-demo.mp4"),
        },
        {
          src: require("../../assets/Images/intro-demo.mp4"),
        },
        {
          src: require("../../assets/Images/intro-demo.mp4"),
        },
      ],
      animation: false,
      section: "bks5a9",
      isInView: false,
      isInViewFlag: false,
      allowToTrack: false,
      allowToTrackFlag: false,
    };
  },
  computed: {
    mainOffer() {
      return useStore().getMainOffer;
    }
  },
  methods: {
    descShortage,
    countIndex() {
      const index = this.$refs.myCarousel.data.currentSlide.value + 1;
      this.currentSlide = index;
    },
    slideSlides(direction){
      if (direction === "left") {
        this.$refs.myCarousel.prev();
      } else {
        this.$refs.myCarousel.next();
      }
      this.updateSliderInfo();
      this.countIndex();
    },

    updateSliderInfo() {
      const sliderInfo = this.$refs.sliderInfoComponent;
      sliderInfo.innerHTML = `Video ${
        this.$refs.myCarousel.data.currentSlide.value + 1
      } z ${this.slides.length}`;
    },

    checkViewport() {
      const element = this.$refs.trackComponent;
      if (element) {
        this.isInView = isInViewport(element);
        if (this.isInView && !this.isInViewFlag) {
          this.isInViewFlag = true;
          this.allowToTrack = true;
          setTimeout(() => {
            if (this.allowToTrack && this.allowToTrackFlag) {
              const arr = []
              arr.push(useStore().mainOffer[0].id)
              httpActions.trackSection(
                this.section,
                arr
              );
            }
            this.allowToTrackFlag = false;
            this.isInViewFlag = false;
          }, 1500);
        }
        if (this.isInView === false) {
          this.allowToTrack = false;
        }
      }
    },
  },
  mounted() {
    this.updateSliderInfo();
    this.animation = true;
    this.checkViewport();
    window.addEventListener("scroll", this.checkViewport);
  },
  beforeUnmount() {
    window.removeEventListener("scroll", this.checkViewport);
  },
  watch: {
    mainOffer(newValue) {
      if (newValue) {
        this.animation = false;
        this.allowToTrackFlag = true;
        this.checkViewport();
        setTimeout(() => {
          this.animation = true;
        }, 100);
      }
    },
    isInView(newVal) {
      if (newVal) {
        this.allowToTrackFlag = true;
      }
    },
  }
};
</script>