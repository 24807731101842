<template>
  <div class="sidebar" ref="sidebarComponent">
    <div class="sidebar__inner">
      <div
        :class="{
          'sidebar__block-date': returnColor === '',
          'sidebar__block-date--bordo': returnColor === '#801C62',
        }"
      >
        <p class="sidebar__text">{{ $t("offerExpiration") }}</p>
        <p class="sidebar__date" ref="dateComponent"></p>
      </div>
      <SideConsultantContainer></SideConsultantContainer>
      <SideOffersContainer
        :compareIsNotActive="compareIsNotActive"
      ></SideOffersContainer>
      <button
        :class="{
          sidebar__compare: returnColor === '',
          'sidebar__compare--bordo': returnColor === '#801C62',
          'sidebar__compare--allowed': allowToClick,
        }"
        @click="openCompareComponent"
        ref="compareButton"
      >
        {{ $t("compareMatched") }}
      </button>
      <button
        v-if="changeOffer && changeOffer.files && changeOffer.files.length > 0"
        class="sidebar__files"
        @click="handleFilesButton"
      >
        {{ $t("yourFilesHeader") }}
      </button>
    </div>
  </div>
</template>

<script>
import SideConsultantContainer from "./SideConsultantContainer.vue";
import SideOffersContainer from "./SideOffersContainer.vue";
import Notiflix from "notiflix";
import { useStore } from "../../store";
import httpActionService from "../../plugins/httpActionService";

export default {
  components: {
    SideConsultantContainer,
    SideOffersContainer,
  },
  data() {
    return {
      compareIsNotActive: true,
      allowToClick: false,
      intervalId: null,
    };
  },
  computed: {
    isShown() {
      return useStore().compareButtonClicked;
    },
    clickedButtonsCount() {
      return useStore().clickedButtonsCount;
    },
    changeOffer() {
      return useStore().data;
    },
    returnColor() {
      return useStore().getColor;
    },
  },
  methods: {
    openCompareComponent() {
      if (this.isShown === true && this.clickedButtonsCount >= 2) {
        if (this.$route.path === "/compare") {
          useStore().set("updateChangeOfferFromCompare", true);
          window.location.reload();
        } else {
          this.$router.push("/compare");
        }
        this.compareIsNotActive = false;
      } else {
        Notiflix.Notify.warning(
          "Wybierz przynajmniej dwie pozycje do porównania"
        );
      }
    },
    loadData() {
      if (useStore().data) {
        this.clearInterval();
        const dateComponent = this.$refs.dateComponent;
        dateComponent.textContent = "";
        const expirationDate = new Date(useStore().data.offer.expirationDate);
        const currentTime = new Date();
        const timeDifference = expirationDate - currentTime;

        if (timeDifference > 0) {
          this.intervalId = setInterval(() => {
            const currentTime = new Date();
            const timeDifference = expirationDate - currentTime;

            if (timeDifference <= 0) {
              this.clearInterval();
              dateComponent.textContent = "0h : 0m : 0s";
            } else {
              const days = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
              const hours = Math.floor(
                (timeDifference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
              );
              const minutes = Math.floor(
                (timeDifference % (1000 * 60 * 60)) / (1000 * 60)
              );
              const seconds = Math.floor((timeDifference % (1000 * 60)) / 1000);

              const formattedHours = hours < 10 ? `0${hours}` : hours;
              const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;
              const formattedSeconds = seconds < 10 ? `0${seconds}` : seconds;

              if (days === 0) {
                dateComponent.textContent = `${formattedHours}h : ${formattedMinutes}m : ${formattedSeconds}s`;
              } else {
                dateComponent.textContent = `${days}d : ${formattedHours}h : ${formattedMinutes}m : ${formattedSeconds}s`;
              }
            }
          }, 1000);
        } else {
          dateComponent.textContent = "Oferta wygasła";
        }
      }
    },
    clearInterval() {
      if (this.intervalId !== null) {
        clearInterval(this.intervalId);
        this.intervalId = null;
      }
    },
    handleFilesButton() {
      useStore().set("filesButtonClicked", true);
      if (this.$route.path === "/compare") {
        this.$router.push("/main");
      }
    },
  },
  mounted() {
    this.loadData();
    if (this.$route.path === "/compare") {
      this.compareIsNotActive = false;
    }
  },
  beforeUnmount() {
    useStore().resetIndex();
    this.clearInterval();
  },
  watch: {
    clickedButtonsCount: function () {
      if (this.clickedButtonsCount >= 2) {
        this.allowToClick = true;
      } else {
        this.allowToClick = false;
      }
    },
    changeOffer(newVal) {
      if (newVal) {
        this.loadData();
      }
    },
  },
};
</script>
