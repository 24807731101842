<template>
    <button class="button__gray" type="submit">{{ ButtonText }}</button>
</template>

<script>
    export default {
        props: {
            ButtonText: String,
        },
        methods: {

        }
    }
</script>