<template>
  <section
    class="configuration"
    ref="configurationComponent"
    :class="{ configuration__animation: animation }"
  >
    <HeadingGray :HeadingText="$t('yourConfiguration')"></HeadingGray>
    <ConfigurationInfo></ConfigurationInfo>
    <ConfigurationEquipment :screen="screen"></ConfigurationEquipment>
  </section>
</template>

<script>
import HeadingGray from "../Header/HeadingGray.vue";
import ConfigurationInfo from "./ConfigurationInfo.vue";
import ConfigurationEquipment from "./ConfigurationEquipment.vue";
import { useStore } from "../../store";
import httpActions from "../../plugins/httpActionService";

export default {
  components: {
    HeadingGray,
    ConfigurationInfo,
    ConfigurationEquipment,
  },
  props: {
    screen: Boolean,
  },
  computed: {
    mainOffer() {
      return useStore().getMainOffer;
    },
  },
  data() {
    return {
      animation: false,
      changeOffer: "fkeud6",
    };
  },
  mounted() {
    if (useStore().configurationScroll) {
      this.$refs.configurationComponent.scrollIntoView({ behavior: "smooth" });
      useStore().resetScrollAction(false);
    }
    this.animation = true;
    this.$nextTick(() => {
      if (useStore().mainOffer[0]) {
        httpActions.trackChangeCar(
          this.changeOffer,
          useStore().mainOffer[0].id
        );
      }
    });
  },
  watch: {
    mainOffer(newValue) {
      if (newValue) {
        this.animation = false;
        if (useStore().mainOffer[0] && useStore().mainOffer[0].id) {
          httpActions.trackChangeCar(
            this.changeOffer,
            useStore().mainOffer[0].id
          );
        }
        setTimeout(() => {
          this.animation = true;
        }, 100);
      }
    },
  },
};
</script>
