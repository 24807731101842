<template>
  <p class="side__text">{{ $t("contactWithConsultant") }}</p>
  <div class="side__container--mobile">
    <svg class="slider__icon sidebar__prev" @click="sideBarSlideLeft">
      <image
        v-if="specialists && specialists.length > 1"
        href="../../assets/Icons/arrow_left.svg"
      ></image>
    </svg>
    <div class="side__block--mobile" ref="sideBlockMobileComponent">
      <SideConsultant
        v-for="(specialist, index) in specialists"
        :key="index"
        :name="specialist.name"
        :proffession="specialist.position"
        :picture="specialist.photo"
        :buttonText="specialist.phoneNumber"
      ></SideConsultant>
    </div>
    <svg class="slider__icon sidebar__next" @click="sideBarSlideRight">
      <image
        v-if="specialists && specialists.length > 1"
        href="../../assets/Icons/arrow_right.svg"
      ></image>
    </svg>
  </div>
</template>

<script>
import SideConsultant from "./SideConsultant.vue";
import { useStore } from "../../store";

export default {
  data() {
    return {
      specialists: "",
      currentPosition: 0,
      slideValue: 212,
    };
  },
  components: {
    SideConsultant,
  },
  methods: {
    sideBarSlideLeft() {
      const block = this.$refs.sideBlockMobileComponent;

      if (this.currentPosition < 0) {
        this.currentPosition += this.slideValue;
        block.style.transition = "transform 0.5s";
        block.style.transform = `translateX(${this.currentPosition}px)`;
      }
    },

    sideBarSlideRight() {
      const block = this.$refs.sideBlockMobileComponent;

      if (this.currentPosition >= 0) {
        this.currentPosition -= this.slideValue;
        block.style.transition = "transform 0.5s";
        block.style.transform = `translateX(${this.currentPosition}px)`;
      }
    },

    getData() {
      if (useStore().data) {
        this.specialists = useStore().data.advisors;
      }
    },
  },
  mounted() {
    this.getData();
  },
};
</script>
